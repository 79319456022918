<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.expanded()">
	<div class="uno-options-icon-unscrollable" (click)="this.toggle()"></div>
</ng-template>

<div class="uno-options-icon-container">
	<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.expanded()">
		<div class="uno-options-list-unscrollable" (click)="this.closing.set(true); this.toggle()"></div>
	</ng-template>
	<div>
		<div class="uno-options-icon-input" [style.width.px]="width" [style.height.px]="height" [ngClass]="{'uno-options-icon-input-border': border}" (click)="this.toggle()" cdkOverlayOrigin #originOverlay="cdkOverlayOrigin">
			<img src="{{selectedOption.icon}}" width="12px" />
			<span class="uno-options-icon-label">{{selectedOption.label}}</span>
			<uno-icon class="uno-options-icon-input-icon" [ngClass]="{'uno-options-icon-rotate': !this.expanded(), 'disabled': false}" color="{{!this.expanded() ? 'var(--gray-5)' : 'var(--primary-70)' }}" [width]="32" [height]="32" src="./assets/components/options/up-specialarrow-icon.svg"></uno-icon>
		</div>

		<!-- Desktop -->
		@if(this.app.device.isDesktop()) {
			<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.expanded()">
				<div class="uno-options-icon-dropdown" [style.width.px]="width" [style.height.px]="dropdownHeight" [ngClass]="{'uno-options-icon-close': this.closing() && !this.expanded()}">
					<!-- Options -->
					<div class="uno-options-icon-dropdown-option-container" [style.width.px]="width">
						<cdk-virtual-scroll-viewport #viewport [itemSize]='30' style="width: 100%; height:100%">
							<div class="uno-options-icon-dropdown-option" (click)="this.writeValue(option)" *cdkVirtualFor="let option of options;">
								<img src="{{option.icon}}" width="12px" />
								<span class="uno-options-icon-dropdown-option-label">{{option.label}}</span>
								@if(option.sublabel) {
									<span class="uno-options-icon-dropdown-option-sublabel">{{option.sublabel}}</span>
								}
							</div>
						</cdk-virtual-scroll-viewport>

					</div>
				</div>
			</ng-template>
		}
	</div>
</div>

<!-- Mobile -->
<ng-template #mobileModal>
	<div class="uno-options-icon-modal" #modalOptions>
		@for (option of options; track $index) {
			<div class="uno-options-icon-modal-option" [id]="'option' + $index" (click)="this.dismissAndWriteValue($event, option)">
				<img class="uno-options-icon-modal-option-icon" src="{{option.icon}}" width="18px" />
				<span class="uno-options-icon-modal-option-label">{{ option.label }}</span>
				@if(option.sublabel) {
					<span class="uno-options-icon-modal-option-sublabel">{{option.sublabel}}</span>
				}
			</div>
		}
	</div>
</ng-template>
