import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NgComponentOutlet} from '@angular/common';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {UnoIconComponent} from 'src/app/components/uno/uno-icon/uno-icon.component';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {Widget} from '../../data/widget-interface';
import {WidgetsMap} from '../../data/widgets-map';

export type ChartData = {
	// Labels to use on the chart.
	labels: string[],
	// Labels to apply on the chart.
	datasets: any[]
}

/**
 * The Dashboard widget component.
 */
@Component({
	selector: 'uno-widget',
	templateUrl: 'widget.component.html',
	styleUrls: ['widget.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		TranslateModule,
		FormsModule,
		UnoIconComponent,
		NgComponentOutlet
	]
})
export class UnoWidgetComponent implements OnInit {
	/**
	 * If the widget can be edited.
	 */
	@Input()
	public editable: boolean = true;

	/**
	 * The widget to show.
	 */
	@Input()
	public widget: DashboardWidget;

	/**
	 * The widget interface.
	 */
	public widgetInterface: Widget;

	/**
	 * The data retrieved from the api to present on the widget.
	 */
	public data: ChartData;

	/**
	 * Callback when the widget is edited.
	 */
	@Output()
	public update = new EventEmitter<DashboardWidget>();

	/**
	 * Callback when the widget is deleted.
	 */
	@Output()
	public delete = new EventEmitter<DashboardWidget>();

	public async ngOnInit(): Promise<void> {
		if (this.widget.type in WidgetTypes) {
			await this.fetchData();
		} else {
			throw new Error('Widget Type does not exist');
		}
	}

	/**
	 * Fetches the corresponding widget and it's values.
	 */
	public async fetchData(widget?: DashboardWidget): Promise<void> {
		if (widget) {
			this.widget = widget;
		} else {
			this.widgetInterface = WidgetsMap.get(this.widget.type).widget;
		}

		this.data = await this.widgetInterface.getData(this.widget);
	}

	/**
	 * Opens the edit modal.
	 */
	public async openEditModal(): Promise<void> {
		if (!this.editable) {
			throw new Error('Widget is not editable.');
		}

		const widget: DashboardWidget = await this.widgetInterface.openEditModal(this.widget);
		this.update.emit(widget);
	}

	/**
	 * Displays a modal to confirm the deletion and emits if confirmed.
	 */
	public async deleteWidget(): Promise<void> {
		if (!this.editable) {
			throw new Error('Widget is not editable.');
		}

		const confirm: boolean = await Modal.confirm(Locale.get('delete'), Locale.get('confirmDelete'));
		if (confirm) {
			this.delete.emit(this.widget);
		}
	}
}
