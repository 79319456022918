import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {GeoJSONSource, LngLatLike, Map, Marker, NavigationControl} from 'mapbox-gl';
import {MapboxStyleDefinition, MapboxStyleSwitcherControl} from 'mapbox-gl-style-switcher';
import {Feature} from 'geojson';
import {UnoSearchBarResult} from 'src/app/components/uno/uno-searchbar-dropdown/uno-searchbar-dropdown.component';
import {Session} from '../../../../../session';
import {Geolocation} from '../../../../../models/geolocation';
import {Environment} from '../../../../../../environments/environment';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {Locale} from '../../../../../locale/locale';
import {MapStylesLabel} from '../../../../../theme/map-styles';
import {GeolocationUtils} from '../../../../../utils/geolocation-utils';
import {CSSUtils} from '../../../../../utils/css-utils';
import {ResizeDetector} from '../../../../../utils/resize-detector';
import {UnoSearchbarDropdownComponent} from '../../../../../components/uno/uno-searchbar-dropdown/uno-searchbar-dropdown.component';
import {AssetListGeolocationAsset, AssetService} from '../../../services/asset.service';
import {GeolocationService} from '../../../../geolocation/geolocation.service';

@Component({
	selector: 'map-page',
	templateUrl: './assets-map.page.html',
	styleUrls: ['assets-map.page.css'],
	standalone: true,
	encapsulation: ViewEncapsulation.None,
	imports: [UnoSearchbarDropdownComponent]
})
export class AssetsMapPage extends ScreenComponent implements OnInit, OnDestroy {
	@ViewChild('mapContainer', {static: true})
	public mapContainer: ElementRef = null;

	public permissions = [UserPermissions.ASSET_PORTFOLIO_MAP];

	public selfStatic: any = AssetsMapPage;

	/**
	 * Assets to be displayed in the map.
	 */
	public assets: AssetListGeolocationAsset[] = [];

	/**
	 * Places to present on map (retrieved from mapbox).
	 */
	public places: Feature[] = [];

	/**
	 * List of mapbox markers used to draw pointer in HTML mode.
	 */
	public markers: Marker[] = [];

	/**
	 * Mapboxgl instance to display and control the map view.
	 */
	public map: Map = null;

	/**
	 * Marker with the user GPS position.
	 */
	public marker: Marker = null;

	/**
	 * Current position in the map.
	 */
	public position: Geolocation = new Geolocation(0, 0, 0);

	/**
	 * Resize detector.
	 */
	public resizeDetector: ResizeDetector = null;

	public static filters = {
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: ['[ap_asset].name', '[ap_asset].tag', '[ap_asset].description', '[ap_asset].id']
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('map');

		await this.createMap();

		await this.getUserLocation();

		this.assets = await this.loadAssets();

		this.drawLayers();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		
		this.removeMapPointsLayer('assets');
		this.removeMapPointsLayer('places');
		this.map.removeSource('mapbox-dem');

		this.resizeDetector.destroy();
		this.map.remove();
	}

	/**
	 * Reload Layers, should be called after style change.
	 */
	public drawLayers(): void {
		this.drawAssetLayer();
		this.drawPlacesLayer();
	}

	/**
	 * Load asset data from database.
	 *
	 * @returns - Asset geolocation list.
	 */
	public async loadAssets(): Promise<AssetListGeolocationAsset[]> {
		const data = await AssetService.listGeolocation({
			search: AssetsMapPage.filters.search,
			searchFields: AssetsMapPage.filters.searchFields
		});

		return data.assets;
	}

	/**
	 * Get user location from GPS or browser location API.
	 */
	public async getUserLocation(): Promise<void> {
		this.position = await GeolocationUtils.getLocation();

		if (!this.marker) {
			this.marker = new Marker({draggable: false});
			this.marker.setLngLat([this.position.longitude, this.position.latitude]);
			this.marker.addTo(this.map);
		} else {
			this.marker.setLngLat([this.position.longitude, this.position.latitude]);
		}

		this.map.flyTo({center: [this.position.longitude, this.position.latitude]});
	}

	/**
	 * Create and configure the map instance.
	 */
	public createMap(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.map = new Map({
				accessToken: Environment.MAPBOX_TOKEN,
				container: this.mapContainer.nativeElement,
				style: Session.settings.mapStyle,
				projection: {name: 'globe'},
				zoom: 13,
				pitch: 0,
				bearing: 0,
				center: [this.position.longitude, this.position.latitude],
				attributionControl: false
			});

			// Click on the label of a point on the map
			this.map.on('click', 'assets-markers', (e) => {
				const feat = this.map.queryRenderedFeatures(e.point, {layers: ['assets-markers']});
				const uuid = feat[0].properties.uuid;
				App.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: uuid});
			});

			this.map.on('style.load', () => {
				this.createLayers();
				this.drawLayers();
				resolve(null);
			});


			// Click on a place label on the map
			this.map.on('click', 'places-markers', (e) => {
				this.map.flyTo({center: e.lngLat});
			});

			// Style switch controls
			const styles: MapboxStyleDefinition[] = [];
			MapStylesLabel.forEach(function(value, key) {
				styles.push({
					title: Locale.get(MapStylesLabel.get(key)),
					uri: key
				});
			});

			this.map.addControl(new MapboxStyleSwitcherControl(styles, {
				eventListeners: {
					onChange: (evt: Event, style: string): boolean => {
						this.map.setStyle(style, {diff: false});
						return true;
					}
				}
			}), 'top-right');
			this.map.addControl(new NavigationControl(), 'top-right');

			this.resizeDetector = new ResizeDetector(this.mapContainer.nativeElement, () => {
				this.map.resize();
			});
		});

	}

	/**
	 * Enable the map layers, should only be called after the map gets loaded.
	 *
	 * Should be called when the map style is changed (which causes a reset).
	 */
	public createLayers(): void {
		if (!this.map) {
			throw new Error('Map instance is not available, create map before calling this method.');
		}

		this.map.setFog({
			range: [8, 20],
			'horizon-blend': 0.3,
			color: '#FFFFFF',
			// @ts-ignore
			'high-color': ['interpolate', ['linear'], ['zoom'], 4, '#161B36', 7, '#add8e6'],
			'space-color': ['interpolate', ['linear'], ['zoom'], 4, '#0B1026', 7, '#d8f2ff'],
			'star-intensity': ['interpolate', ['linear'], ['zoom'], 4, 0.3, 7, 0.0]
		});

		this.map.addSource('mapbox-dem', {
			type: 'raster-dem',
			url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
			tileSize: 512,
			maxzoom: 14
		});

		this.map.setTerrain({
			source: 'mapbox-dem',
			exaggeration: 1.0
		});

		this.createMapPointsLayer('assets', CSSUtils.getVariable('--primary-60'));
		this.createMapPointsLayer('places', CSSUtils.getVariable('--special-orange-2'));
	}

	/**
	 * Create a source and its layers for map.
	 *
	 * Will create 4 layers ("name-clusters", "name-clusters-count", "name-markers" and "name-point") and 1 data source "name".
	 * 
	 * @param name - Name of the map source and layers prefix.
	 * @param color - The color string for the point features on map.
	 */
	public createMapPointsLayer(name: string, color: string): void {
		if (!this.map) {
			throw new Error('Map instance is not available, create map before calling this method.');
		}

		// GeoJSON data source used to draw points in WebGL mode.
		this.map.addSource(name, {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: []
			},
			cluster: true,
			clusterMaxZoom: 14,
			clusterRadius: 30
		});

		// Base circle for clustered points
		this.map.addLayer({
			id: name + '-clusters',
			type: 'circle',
			source: name,
			filter: ['has', 'point_count'],
			paint: {
				'circle-color': ['step', ['get', 'point_count'], CSSUtils.getVariable('--success-normal'), 1e2, CSSUtils.getVariable('--warning-normal'), 1e3, CSSUtils.getVariable('--error-normal')],
				'circle-radius': ['step', ['get', 'point_count'], 20, 1e2, 25, 1e3, 30]
			}
		});

		// Count of the clustered points
		this.map.addLayer({
			id: name + '-clusters-count',
			type: 'symbol',
			source: name,
			filter: ['has', 'point_count'],
			layout: {
				'text-field': '{point_count_abbreviated}',
				'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
				'text-size': 13
			},
			paint: {'text-color': CSSUtils.getVariable('--light')}
		});

		// Individual feature text markers
		this.map.addLayer({
			id: name + '-markers',
			type: 'symbol',
			source: name,
			filter: ['!', ['has', 'point_count']],
			layout: {
				'text-field': '{text}',
				'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
				'text-size': 12,
				'text-offset': [0, 1],
				'text-anchor': 'top'
			},
			paint: {
				'text-color': CSSUtils.getVariable('--dark'),
				'text-halo-color': CSSUtils.getVariable('--light'),
				'text-halo-width': 1
			}
		});

		// Individual feature points
		this.map.addLayer({
			id: name + '-point',
			type: 'circle',
			source: name,
			filter: ['!', ['has', 'point_count']],
			paint: {
				'circle-color': color,
				'circle-radius': 5,
				'circle-stroke-width': 1,
				'circle-stroke-color': CSSUtils.getVariable('--light')
			}
		});
	}

	/**
	 * Remove map point layers and source created using the createMapPointsLayer() method.
	 *
	 * @param name - Name of the layer.
	 */
	public removeMapPointsLayer(name: string): void {
		this.map.removeLayer(name + '-clusters');
		this.map.removeLayer(name + '-clusters-count');
		this.map.removeLayer(name + '-markers');
		this.map.removeLayer(name + '-point');
		this.map.removeSource(name);
	}

	/**
	 * Create points layer to draw the position markers of the assets.
	 */
	public drawAssetLayer(): void {
		const features: Feature[] = [];

		// Create a list of features from the assets retrieved from the server
		for (let i = 0; i < this.assets.length; i++) {
			const position = this.assets[i].position;
			features.push({
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: [position.longitude, position.latitude]
				},
				id: this.assets[i].uuid,
				properties: {
					uuid: this.assets[i].uuid,
					text: this.assets[i].tag
				}
			});
		}

		const source: GeoJSONSource = this.map.getSource('assets') as GeoJSONSource;
		source.setData({
			type: 'FeatureCollection',
			features: features
		});
	}

	/**
	 * Create places layer to draw the position markers of the places found on search.
	 */
	public drawPlacesLayer(): void {
		const placesSource: GeoJSONSource = this.map.getSource('places') as GeoJSONSource;
		placesSource.setData({
			type: 'FeatureCollection',
			features: this.places
		});
	}

	/**
	 * Get search results to present on the search bar.
	 *
	 * @param search - The text of search filter.
	 * @returns Results to be presented on the searchbar dropdown.
	 */
	public async getSearchResults(search: string = ''): Promise<UnoSearchBarResult[]> {
		// Update search text
		AssetsMapPage.filters.search = search;

		const results = [];

		// Get places
		this.places = await GeolocationService.searchPlaces(search, 10);
		for (const place of this.places) {
			results.push({
				// @ts-ignore
				value: place.center,
				label: place['place_name']
			});
		}

		// Limit results of asset
		this.assets = await this.loadAssets();
		for (const asset of this.assets) {
			results.push({
				value: [asset.position.longitude, asset.position.latitude],
				label: asset.tag || asset.name
			});
		}

		this.drawLayers();

		return results;
	};

	/**
	 * Update the assets list with the search used term.
	 *
	 * @param coordinates - The chosen option value coordinates, from the search dropdown.
	 */
	public async onSearchSelect(coordinates: LngLatLike): Promise<void> {
		if (!coordinates) {
			AssetsMapPage.filters.search = '';

			this.assets = await this.loadAssets();
			this.places = [];
			this.drawLayers();
		} else if (this.map) {
			this.map.flyTo({center: coordinates});
		}

	}
}
