import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {Chart} from 'chart.js';
import {NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../../../utils/css-ng-style';
import {CSSUtils} from '../../../../../utils/css-utils';
import {WidgetComponent} from '../widget-component';

@Component({
	selector: 'dashboard-line-chart-widget',
	templateUrl: './dashboard-line-chart-widget.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle]
})
export class DashboardLineChartWidgetComponent implements OnInit, OnDestroy, WidgetComponent {
	@ViewChild('canvas', {static: true})
	public canvas: ElementRef = null;

	@Input()
	public data: any = null;

	/**
	 * Config of the widget.
	 */
	@Input()
	public config: any = null;

	@Input()
	public ngStyle: CssNgStyle = {height: '230px'};

	public context: CanvasRenderingContext2D = null;

	public chart: Chart = null;

	public ngOnInit(): void {

		this.context = this.canvas.nativeElement.getContext('2d');

		this.chart = new Chart(this.context, {
			type: 'line',
			data: this.data,
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						labels: {
							usePointStyle: true,
							boxWidth: 8,
							boxHeight: 8,
							font: {
								family: 'Nunito',
								size: 12,
								lineHeight: 24
							}
						},
						display: true,
						position: 'bottom'
					}
				},
				scales: {
					y: {
						ticks: {
							color: CSSUtils.getVariable('--gray-8'),
							font: {
								family: 'Nunito',
								size: 10
							}
						},
						grid: {color: CSSUtils.getVariable('--gray-11')},
						border: {color: CSSUtils.getVariable('--gray-11')}
					},
					x: {
						ticks: {
							color: CSSUtils.getVariable('--gray-8'),
							font: {
								family: 'Nunito',
								size: 10
							}
						},
						grid: {
							color: CSSUtils.getVariable('--gray-11'),
							offset: true
						},
						border: {color: CSSUtils.getVariable('--gray-11')}
					}
				},
				elements: {
					point: {
						borderWidth: 0,
						radius: 10,
						backgroundColor: 'rgba(0,0,0,0)'
					}
				}
			}
		});
	}

	/**
	 * Update the graph data should be called when the data is changed.
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (this.chart && changes.data?.currentValue) {
			this.chart.data.datasets = changes.data.currentValue.datasets;
			this.chart.data.labels = changes.data.currentValue.labels;

			this.chart.update();
		}
	}

	public ngOnDestroy(): void {
		if (this.chart) {
			this.chart.destroy();
			this.chart = null;
		}
	}
}
