<!-- Transparent background to prevent scrolling and close the dropdowns on click -->
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.backdrop && this.expanded">
	<div class="uno-searchbar-dropdown-unscrollable" (click)="this.expanded = false"></div>
</ng-template>

<div class="uno-searchbar-dropdown-container" #container [ngStyle]="this.ngStyle" [ngClass]="{'uno-searchbar-dropdown-disabled': this.disabled, 'uno-searchbar-unfocused': !this.expanded}" cdkOverlayOrigin #originOverlay="cdkOverlayOrigin">
	<uno-input [ngStyle]="{width: '100%', height: '100%'}" (click)="this.expand();" (ngModelChange)="this.inputChange($event);" [ngClass]="{'uno-searchbar-dropdown-input': true, 'uno-searchbar-dropdown-disabled': this.disabled, 'uno-searchbar-unfocused': !this.expanded}" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.searchValue" [autocomplete]="this.autocomplete" [disabled]="this.disabled" [placeholder]="this.placeholder" [debounce]="this.debounce"></uno-input>

	@if (this.searchValue.length === 0) {
		<uno-icon class="uno-searchbar-icon" [width]="28" [height]="28" color="{{!this.disabled ? 'var(--primary-70)' : 'var(--gray-8)'}}" src="./assets/components/searchbar/search-icon.svg"></uno-icon>
	}
	@if (this.searchValue.length > 0) {
		<uno-icon class="uno-searchbar-icon" [width]="28" [height]="28" color="{{!this.disabled ? 'var(--primary-70)' : 'var(--gray-8)'}}" src="./assets/components/searchbar/close-icon.svg" (click)="this.clear()"> </uno-icon>
	}
</div>

<!-- The dropdown that holds the options -->
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.expanded">
	<div [ngStyle]="{width: this.optionsWidth + 'px'}" class="uno-searchbar-dropdown-dropdown">
		<div class="uno-searchbar-dropdown-dropdown-container">
			@for (option of this.options; track option) {
				<div class="uno-searchbar-dropdown-option" (click)="this.setValue(option)">
					<span class="uno-searchbar-dropdown-label">{{option.label | translate}}</span>
				</div>
			}
		</div>
		<div class="uno-scrollbar-padding"></div>
	</div>
</ng-template>
