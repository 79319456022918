import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {CSSUtils} from 'src/app/utils/css-utils';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Session} from 'src/app/session';
import {cloneDeep} from 'lodash-es';
import {WidgetViewTypes} from 'src/app/models/dashboards/widget-view-types';
import {Widget} from '../../widget-interface';
import {ChartData} from '../../../components/widget/widget.component';
import {DashboardDoughnutChartWidgetComponent} from '../../../components/widgets/doughnut-chart-widget/dashboard-doughnut-chart-widget.component';
import {WidgetBaseLayout} from '../../../screens/widget-layout';

export class WidgetAtexActionPlanProgress implements Widget {
	public type = WidgetTypes.ATEX_ACTION_PLAN_PROGRESS;

	public component = DashboardDoughnutChartWidgetComponent;

	public config = function(): void {
		this.chartType = WidgetViewTypes.DOUGHNUT_CHART;
	};

	public permissions = [UserPermissions.ACTION_PLAN];

	public async getData(widgetData: DashboardWidget): Promise<ChartData> {
		const request = await Service.fetch(ServiceList.atex.actionPlan.countState, null, null, null, Session.session);
		const result = request.response;

		const chartData = {
			labels: [Locale.get('underWork'), Locale.get('waitingClient'), Locale.get('approved'), Locale.get('rejected'), Locale.get('blocked'), Locale.get('waitingReinspection'), Locale.get('finished')],
			datasets: [
				{
					label: '',
					data: [result.created, result.waitingClient, result.accepted, result.rejected, result.blocked, result.waitingReinspection, result.done],
					backgroundColor: [
						CSSUtils.getVariable('--special-blue-2'),
						CSSUtils.getVariable('--special-yellow-1'),
						CSSUtils.getVariable('--special-green-3'),
						CSSUtils.getVariable('--special-red-1'),
						CSSUtils.getVariable('--special-orange-1'),
						CSSUtils.getVariable('--special-blue-1'),
						CSSUtils.getVariable('--special-violet-1')
					],
					borderColor: [
						CSSUtils.getVariable('--special-blue-2'),
						CSSUtils.getVariable('--special-yellow-1'),
						CSSUtils.getVariable('--special-green-3'),
						CSSUtils.getVariable('--special-red-1'),
						CSSUtils.getVariable('--special-orange-1'),
						CSSUtils.getVariable('--special-blue-1'),
						CSSUtils.getVariable('--special-violet-1')
					],
					cutout: '70%'
				}
			]
		};

		return chartData;
	};

	public async openEditModal(widget: DashboardWidget): Promise<DashboardWidget> {
		const w = cloneDeep(widget);
		
		widget = await Modal.form(Locale.get('edit'), w, WidgetBaseLayout);

		return widget;
	}
}
