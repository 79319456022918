/**
 * Possible modes for bulk exports.
 */
export const ExportMode = {
	// Separated files exported
	SEPARATED_FILES: 0,
	// Files exported on a single ZIP file
	ZIP: 1
};

export const ExportModeLabel: Map<number, string> = new Map([
	[ExportMode.SEPARATED_FILES, 'sepataredFiles'],
	[ExportMode.ZIP, 'compressedZipFile']
]);

