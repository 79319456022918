export const Environment = {
	PRODUCTION: false,
	CLIENT_ID: 'development',
	CLIENT_FEATURES: [
		'afi',
		'dl50'
	],
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.354.18',
	MODULES: [
		'atex-inspections',
		'digital-twin',
		'asset-planning',
		'gap-analysis',
		'dl-50',
		'inspections',
		'observations',
		'pipeline-integrity',
		'epi',
		'repairs'
	],
	TIMESTAMP: '2025-02-11T14:39:33.210Z',
	COMMIT: '22725c07c3a8716a06314583e7dafd3b7b9fa3fe',
	BRANCH: 'development',
	API_SERVER: 'https://development-uno-api.unoplatform.io',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://development-uno-resources.unoplatform.io',
	FILE_CONVERTER_SERVER: 'https://development-uno-file-converter.unoplatform.io',
	PIPELINE_INTEGRITY_SERVER: 'https://development-uno-pipeline-integrity.unoplatform.io',
	DIGITAL_TWIN_SERVER: 'https://development-uno-digital-twin.unoplatform.io'
};
