import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {CSSUtils} from 'src/app/utils/css-utils';
import {AtexInspectionService} from 'src/app/modules/atex/inspections/services/atex-inspection.service';
import {AtexInspectionResult, AtexInspectionResultLabel} from 'src/app/models/atex-inspections/inspections/atex-inspection-result';
import {cloneDeep} from 'lodash-es';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {WidgetViewTypes} from 'src/app/models/dashboards/widget-view-types';
import {ChartData} from 'chart.js';
import {WidgetBaseLayout} from '../../../screens/widget-layout';
import {Widget} from '../../widget-interface';
import {DashboardDoughnutChartWidgetComponent} from '../../../components/widgets/doughnut-chart-widget/dashboard-doughnut-chart-widget.component';

export class WidgetAtexInspectionResult implements Widget {
	public type = WidgetTypes.ATEX_INSPECTION_RESULTS;

	public config = function(): void {
		this.chartType = WidgetViewTypes.DOUGHNUT_CHART;
	};

	public component = DashboardDoughnutChartWidgetComponent;

	public permissions = [UserPermissions.ATEX_INSPECTION];

	public async getData(widget: DashboardWidget): Promise<ChartData> {
		// Count number of inspections of each type
		const request = await AtexInspectionService.countStatusResult(true, false);
		const result = request.inspections.result;

		const chartData = {
			labels: [Locale.get(AtexInspectionResultLabel.get(AtexInspectionResult.APPROVED)), Locale.get(AtexInspectionResultLabel.get(AtexInspectionResult.CONDITIONALLY_APPROVED)), Locale.get(AtexInspectionResultLabel.get(AtexInspectionResult.FAILED)), Locale.get(AtexInspectionResultLabel.get(AtexInspectionResult.NONE))],
			datasets: [
				{
					label: '',
					data: [result.approved, result.conditionallyApproved, result.failed, result.none],
					backgroundColor: [
						CSSUtils.getVariable('--success-normal'),
						CSSUtils.getVariable('--warning-normal'),
						CSSUtils.getVariable('--error-normal'),
						CSSUtils.getVariable('--primary-70')
					],
					borderColor: [
						CSSUtils.getVariable('--success-normal'),
						CSSUtils.getVariable('--warning-normal'),
						CSSUtils.getVariable('--error-normal'),
						CSSUtils.getVariable('--primary-70')
					],
					cutout: '70%'
				}
			]
		};

		return chartData;
	};

	public async openEditModal(widget: DashboardWidget): Promise<DashboardWidget> {
		const w = cloneDeep(widget);
		
		widget = await Modal.form(Locale.get('edit'), w, WidgetBaseLayout);

		return widget;
	}
}
