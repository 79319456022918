<uno-content [borders]="true">
	<div class="asset-calendar-list-events-container">
		<div class="asset-calendar-list-events-topbar-container">
			<span style="font-size: 18px; font-weight: 600; line-height: 24px; color: var(--gray-0)">{{'assetEvents' | translate}}</span>
			<uno-icon [width]="32" [height]="32" color="primary" src="assets/icons/uno/close.svg" (click)="this.closeModal();"></uno-icon>
		</div>
		<div style="height: 70px;display:flex; flex-direction: column;">
			<div>
				<span class="asset-calendar-list-events-label">{{'assetTag' | translate}}: </span>
				<span class="asset-calendar-list-events-text">{{this.asset.tag}}</span>
			</div>
			<div>
				<span class="asset-calendar-list-events-label">{{'assetName' | translate}}: </span>
				<span class="asset-calendar-list-events-text">{{this.asset.name}}</span>
			</div>
		</div>
		<div>
			<!-- The titles -->
			<div class="asset-calendar-list-title" [ngStyle]="{'background-color': session.settings.theme ? 'var(--gray-12)' : 'var(--special-blue-2)', 'display':'flex', 'align-items':'center', 'justify-content': 'space-between'}">
				<span style="color: var(--gray-5); font-size: 12px; padding-left: 30px">{{'name' | translate}}</span>
				<div class="asset-calendar-list-date-status-container">
					<span class="asset-calendar-list-events-label">{{'date' | translate}}</span>
					<span class="asset-calendar-list-events-label">{{'status' | translate}}</span>
				</div>

			</div>
			@if (events.length === 0) {
				<uno-no-data></uno-no-data>
			}
			<!-- The container for the event list options -->
			<cdk-virtual-scroll-viewport [itemSize]="46" [ngStyle]="{'height': '400px', 'width': '100%'}">
				<!-- Each option on the event list -->
				<div class="asset-calendar-list-event-option" [ngStyle]="{'border-left': '4px solid ' + event.color.primary, 'background-color':i%2 ? 'var(--gray-13)': 'var(--gray-14)'}" *cdkVirtualFor="let event of this.events; let i = index">
					<!-- The left side of the event list option -->
					<div class="asset-calendar-list-event-option-left-side" [ngStyle]="{'cursor': this.hasActionAssociated(event) ? 'pointer': 'auto'}" (click)="this.occurrenceClicked(event)">
						<span class="asset-calendar-list-event-option-title" style="flex: 1 0 40px !important;">
							<img src="{{priorityIcon.get(event.meta.occurrence.priority)}}">
							{{event.title}}
						</span>

						<!-- The starting time of the event -->
						<span class="asset-calendar-list-event-option-title" style="flex: 0 0 160px !important;white-space: normal;">{{event.start.toLocaleString(locale.code, {year: "numeric",month: "long",day: "numeric"})}}</span>

						<!-- The status of the event -->
						<span class="asset-calendar-list-event-option-status" [ngStyle]="{'height': '25px', 'visibility': (this.hasActionAssociated(event) || event.meta.occurrence.status > 1) ? 'visible': 'hidden', 'background-color': (event.meta.occurrence.status > 1 ? calendarEventStatusBackground.get(event.meta.occurrence.status) : calendarEventStatusBackground.get(calendarEventStatus.TODO)), 'color' :'var(--dark)'}"> {{(event.meta.occurrence.status > 1 ? calendarEventStatusLabel.get(event.meta.occurrence.status): calendarEventStatusLabel.get(calendarEventStatus.TODO)) | translate | uppercase}}
						</span>
					</div>
				</div>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>

	<!-- The legend for the calendar -->
	<div class="asset-event-list-legend">
		<!-- Legend containing the event subtypes -->
		@for (color of this.calendarEventSubtypeColors | keyvalue; track color) {
			<div class="asset-event-list-legend-member">
				<span class="asset-event-list-legend-icon" [ngStyle]="{'background-color':this.calendarEventSubtypeColors.get(color.key)}"></span>
				<span class="asset-event-list-legend-label">{{this.calendarEventSubtypesLabel.get(color.key) | translate}}</span>
			</div>
		}
	</div>
</uno-content>
