import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
	WritableSignal,
	signal
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Map, Marker, NavigationControl, Popup, ScaleControl} from 'mapbox-gl';
import {Vector2} from 'three';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {MapboxStyleDefinition, MapboxStyleSwitcherControl} from 'mapbox-gl-style-switcher';
import {cloneDeep} from 'lodash';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {PipelineService} from 'src/app/modules/pipeline-integrity/services/pipeline.service';
import {PlantsService} from 'src/app/modules/pipeline-integrity/services/plant.service';
import {LDSAlarmHistoryService} from 'src/app/modules/pipeline-integrity/services/lds-alarm.service';
import {LDSAlarmLevel} from 'src/app/models/pipeline-integrity/leak-detection/lds-alarm-level';
import {UnoFormComponent} from '../../../../../../components/uno-forms/uno-form/uno-form.component';
import {UnoFormFieldTypes} from '../../../../../../components/uno-forms/uno-form/uno-form-field-types';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {PipelineLayout} from '../pipeline-layout';
import {generateUUID, UUID} from '../../../../../../models/uuid';
import {Pipeline} from '../../../../../../models/pipeline-integrity/pipeline/pipeline';
import {MapStyles, MapStylesLabel} from '../../../../../../theme/map-styles';
import {Geolocation} from '../../../../../../models/geolocation';
import {Environment} from '../../../../../../../environments/environment';
import {Segment} from '../../../../../../models/pipeline-integrity/pipeline/segment';
import {CMP} from '../../../../../../models/pipeline-integrity/mot/cmp';
import {MotCmpLayoutSimplified} from '../../../mot/cmp/mot-cmp-layout';
import {Feature} from '../../../../../../models/pipeline-integrity/pipeline/feature';
import {FeatureLayoutSimplified} from '../../feature-layout';
import {GeolocationUtils} from '../../../../../../utils/geolocation-utils';
import {CSSUtils} from '../../../../../../utils/css-utils';
import {FeatureType, FeatureTypeColor, FeatureTypeLabel} from '../../../../../../models/pipeline-integrity/pipeline/feature-type';
import {MathUtils} from '../../../../../../utils/math-utils';
import {Connection} from '../../../../../../models/pipeline-integrity/pipeline/connection';
import {LDS} from '../../../../../../models/pipeline-integrity/leak-detection/lds';
import {LdsLayoutSimplified} from '../../../leak-detection/lds-layout';
import {Plant} from '../../../../../../models/pipeline-integrity/pipeline/plant';
import {UnoFormField} from '../../../../../../components/uno-forms/uno-form/uno-form-field';
import {ResizeDetector} from '../../../../../../utils/resize-detector';
import {SegmentLayoutSimplified} from '../../segment-layout';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {PermissionsPipe} from '../../../../../../pipes/permissions.pipe';

export class FeaturePID extends Feature {
	/**
	 * Mapbox point identifier.
	 */
	public pid: string | number = '';
}

export class CMPPID extends CMP {
	/**
	 * Mapbox point identifier.
	 */
	public pid: string | number = '';
}

export class LDSPID extends LDS {
	/**
	 * Mapbox point identifier.
	 */
	public pid: string | number = '';

	/**
	 * Level of the alarm.
	 */
	public alarmLevel: LDSAlarmLevel = null;
}

export type SegmentWithDistance = {segment: Segment, startPoint: Vector2, endPoint: Vector2, distance: number}

/**
 * Possible point types drawn on map.
 */
export enum PointType {
	lds = 'lds',
	cmp = 'cmp',
	feature = 'feature'
}

/**
 * Layout used to represent a new point in the map.
 *
 * Can be used to create CMP, LDS or features in the map.
 */
export const PipelineMapPointLayout: UnoFormField[] = [
	{
		required: true,
		attribute: 'type',
		label: 'type',
		type: UnoFormFieldTypes.OPTIONS,
		options: [
			{
				value: PointType.cmp,
				label: 'cmp'
			},
			{
				value: PointType.lds,
				label: 'lds'
			},
			{
				value: PointType.feature,
				label: 'feature'
			}
		],
		editable: true
	}
];


/**
 * Represents a entry in the map legend list.
 */
export class PipelineMapFeatureFilter {
	/**
	 * Label of the filter
	 */
	public label: string = null;
	
	/**
	 * Point type associated.
	 */
	public type: PointType = null;

	/**
	 * Feature type associated.
	 */
	public featureType: number = null;

	/**
	 * Color to display in the interface.
	 */
	public color: string = null;

	/**
	 * Indicates if the legend is enabled.
	 */
	public enabled: boolean = true;

	public constructor(label: string, type: PointType, featureType: number, color: string, enabled: boolean = true) {
		this.label = label;
		this.type = type;
		this.featureType = featureType;
		this.color = color;
		this.enabled = enabled;
	}
}

@Component({
	selector: 'pipeline-edit-page',
	templateUrl: 'pipeline-edit.page.html',
	styleUrls: ['./pipeline-edit.page.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CommonModule, IonicModule, UnoFormModule, UnoButtonComponent, TranslateModule, PermissionsPipe]
})
export class PipelineEditPage extends ScreenComponent implements OnInit, OnDestroy {
	public app = App;

	public layout = PipelineLayout;

	public userPermissions = UserPermissions;

	public session = Session;

	@ViewChild('sidebar', {static: true})
	public sidebar: ElementRef;

	@ViewChild('map', {static: true})
	public mapDiv: ElementRef;

	@ViewChild('form', {static: false})
	public form: UnoFormComponent = null;

	@ViewChild('mapContainer', {static: true})
	public mapContainer: ElementRef;

	public permissions = [
		UserPermissions.PIPELINE_INTEGRITY_PIPELINE_CREATE,
		UserPermissions.PIPELINE_INTEGRITY_PIPELINE_EDIT,
		UserPermissions.PIPELINE_INTEGRITY_PIPELINE_DELETE
	];

	/**
	 * Indicates if the component is visible or not in the DOM tree.
	 *
	 * Used to keep track of the component state and refresh the screen state.
	 */
	public visible: boolean = false;

	/**
	 * Mapboxgl instance to display and control the map view.
	 */
	public map: Map = null;

	/**
	 * Mapbox draw instance to draw on map.
	 */
	public draw: MapboxDraw = null;

	/**
	 * Marker with the user GPS position.
	 */
	public marker: Marker = null;

	/**
	 * Current position in the map.
	 */
	public position: Geolocation = new Geolocation(0, 0);

	/**
	 * List of CMP's drawn on map
	 */
	public cmps: CMPPID[] = [];

	/**
	 * List of CMP's to delete
	 */
	public cmpsToDelete: CMPPID[] = [];

	/**
	 * List of LDS drawn on map
	 */
	public lds: LDSPID[] = [];

	/**
	 * List of LDS to delete
	 */
	public ldsToDelete: LDSPID[] = [];

	/**
	 * List of Features drawn on map
	 */
	public features: FeaturePID[] = [];

	 /**
	 * List of Features to delete
	 */
	public featuresToDelete: FeaturePID[] = [];

	/**
	 * List of segments drawn on map.
	 * 
	 * Used to check which segments are already drawn on map.
	 */
	public segments: Segment[] = [];

	/**
	 * List of segments loaded from the API.
	 * 
	 * To compare with the edited data and prepare the new data to set on API.
	 */
	public segmentsLoaded: Segment[] = [];
	
	/**
	 * Pipeline being edited/created on this screen, data is fetched from the API.
	 */
	public pipeline: Pipeline = null;

	/**
	 * Plant where the pipeline belongs, data is fetched from the API.
	 */
	public plant: Plant = null;

	/**
	 * Flag to indicate if the screen is being used to create a new entry.
	 */
	public createMode: boolean = false;

	/**
	 * Resize detector.
	 */
	public resizeDetector: ResizeDetector = null;

	/**
	 * Indicates if the sidebar is expanded or collapsed.
	 */
	public expanded: WritableSignal<boolean> = signal(true);

	/**
	 * Legend entry to present in the map.
	 */
	public legend: WritableSignal<PipelineMapFeatureFilter[]> = signal([]);

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.pipeline = null;
		this.createMode = false;

		this.resizeDetector = new ResizeDetector(this.mapContainer.nativeElement, () => {
			if (this.map) {
				this.map.resize();
			}
		});

		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		// Create pipeline
		if (data.createMode) {
			App.navigator.setTitle('create');
			
			this.createMode = true;
			this.pipeline = new Pipeline();

			// Set plant UUID related to this pipeline by default
			if (data.plantUuid) {
				this.pipeline.plantUuid = data.plantUuid;
				this.plant = await PlantsService.get(data.plantUuid);
			} else {
				App.navigator.pop();
				throw new Error('Missing plant UUID');
			}
		// Edit pipeline, load specific pipeline
		} else {
			App.navigator.setTitle('edit');

			this.pipeline = await PipelineService.get(data.uuid);
			this.plant = await PlantsService.get(this.pipeline.plantUuid);

			if (this.pipeline.name) {
				App.navigator.setTitle(this.pipeline.name);
			}
		}
		
		this.createMap();
		
		// When plant has coordinates, zoom to position, default zoom to current position
		this.updatePosition(this.plant.position || await GeolocationUtils.getLocation());
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();

		this.resizeDetector.destroy();
		this.map.remove();
	}

	/**
	 * Toggle state of the sidebar.
	 */
	public toggleSidebar(): void {
		const expanded = !this.expanded();
		this.expanded.set(expanded);

		this.mapDiv.nativeElement.className = expanded ? 'pipeline-edit-map-expanded' : 'pipeline-edit-map-collapsed';
		this.sidebar.nativeElement.className = expanded ? 'pipeline-edit-sidebar-expanded' : 'pipeline-edit-sidebar-collapsed';
	}

	/**
	 * Create/update pipeline, segments, and points on the API.
	 */
	public async update(): Promise<void> {
		const drawnElements = this.draw.getAll();

		// No elements drawn, just save the pipeline
		if (!drawnElements || drawnElements.features.length === 0) {
			if (!this.form.requiredFilled()) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}
		
			await Service.fetch(this.createMode ? ServiceList.pipelineIntegrity.pipeline.create : ServiceList.pipelineIntegrity.pipeline.update, null, null, this.pipeline, Session.session);

			App.navigator.pop();
			Modal.toast(Locale.get('updatedSuccessfully'));
			return;
		}

		// Check if there is segment drawn
		const hasSegments: boolean = drawnElements.features.findIndex((item: any) => {
			return item.geometry.type === 'LineString';
		}) > -1;

		// Has segments
		if (hasSegments) {
			// Save pipeline
			if (!this.form.requiredFilled()) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}

			const request = await Service.fetch(this.createMode ? ServiceList.pipelineIntegrity.pipeline.create : ServiceList.pipelineIntegrity.pipeline.update, null, null, this.pipeline, Session.session);
			this.pipeline.uuid = request.response.uuid;

			// Check drawn segments and points
			const segmentDistances: any[] = [];
			let point: any;

			for (const itemF of drawnElements.features) {
				if (itemF.geometry.type === 'LineString') {
					// Add pipelineUuid created or updated to segments
					for (const s of this.segments) {
						s.pipelineUuid = this.pipeline.uuid;
					}

					// Foreach segment convert coordinates to axis coordinates and get distances between a point and segments
					for (const seg of this.segments) {
						for (const item of drawnElements.features) {
							if (item.geometry.type === 'Point') {
								point = GeolocationUtils.datumsToSpherical(item.geometry.coordinates[1], item.geometry.coordinates[0]);
								const start = GeolocationUtils.datumsToSpherical(seg.startPoint.latitude, seg.startPoint.longitude);
								const end = GeolocationUtils.datumsToSpherical(seg.endPoint.latitude, seg.endPoint.longitude);
								segmentDistances.push({segmentUuid: seg.uuid, elementId: item.id, startPoint: start, endPoint: end, distance: MathUtils.distancePointLineSegment(point, [start, end])});
							}
						}
					}

					// For all segments and points, returns the smallest distance of a point to a segment
					const segmentAssoc = segmentDistances.reduce((op, {elementId, segmentUuid, distance}) => {
						op[elementId] = op[elementId] || {elementId: elementId, segmentUuid: segmentUuid, minDistance: Infinity};
						if (op[elementId].minDistance > distance) {
							op[elementId].minDistance = distance;
							op[elementId].segmentUuid = segmentUuid;
						}
						return op;
					}, {});

					// Updates points and features data with the segmentUuid if there is a match between pid
					const points: {pid: any, segmentUuid: UUID}[] = this.cmps.concat(this.features as any[]);
					for (const p of points) {
						if (p.pid in segmentAssoc) {
							p.segmentUuid = segmentAssoc[p.pid].segmentUuid;
						}
					}

					const connections = this.getSegmentConnections(this.segments);
					const segments = this.segments.slice();
					
					// Save segments and connections
					await Service.fetch(this.createMode ? ServiceList.pipelineIntegrity.pipeline.segment.createBatch : ServiceList.pipelineIntegrity.pipeline.set, null, null, {connections: connections, segments: segments}, Session.session);

					// Save LDS
					for (const l of this.lds) {
						const data = cloneDeep(l);
						delete data.pid;
						delete data.updatedAt;
						delete data.createdAt;
						delete data.alarmLevel;

						const service = ServiceList.pipelineIntegrity.leakDetection.lds;
						await Service.fetch(l.uuid === null ? service.create : service.update, null, null, data, Session.session);
					}

					// Save CMP
					for (const c of this.cmps) {
						const data = cloneDeep(c);
						delete data.pid;

						const service = ServiceList.pipelineIntegrity.mot.cmp;
						await Service.fetch(c.uuid === null ? service.create : service.update, null, null, data, Session.session);
					}

					// Save Features
					for (const f of this.features) {
						const data = cloneDeep(f);
						delete data.pid;

						const service = ServiceList.pipelineIntegrity.pipeline.feature;
						await Service.fetch(f.uuid === null ? service.create : service.update, null, null, data, Session.session);
					}

					// Delete LDS's removed from map
					for (const l of this.ldsToDelete) {
						await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.delete, null, null, {uuid: l.uuid}, Session.session);
					}
					// Delete CMP's removed from map
					for (const c of this.cmpsToDelete) {
						await Service.fetch(ServiceList.pipelineIntegrity.mot.cmp.delete, null, null, {uuid: c.uuid}, Session.session);
					}
					// Delete features removed from map
					for (const f of this.featuresToDelete) {
						await Service.fetch(ServiceList.pipelineIntegrity.pipeline.feature.delete, null, null, {uuid: f.uuid}, Session.session);
					}

					App.navigator.pop();
					Modal.toast(Locale.get('updatedSuccessfully'));
					return;
				}
			}
		} else {
			Modal.alert(Locale.get('error'), Locale.get('errorMissingPipeline'));
		}
	}

	/**
	 * Delete pipeline.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.pipelineIntegrity.pipeline.delete, null, null, {uuid: this.pipeline.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			this.map.remove();
			App.navigator.pop();
		}
	}

	/**
	 * Create and configure the map instance.
	 */
	public createMap(): void {
		this.map = new Map({
			accessToken: Environment.MAPBOX_TOKEN,
			container: this.mapContainer.nativeElement,
			style: MapStyles.SATELLITE,
			zoom: 13,
			pitch: 0,
			bearing: 0,
			center: [this.position.longitude, this.position.latitude],
			attributionControl: false
		});

		if (!this.createMode) {
			// On load render segments, CMP's and features of Pipeline
			this.map.on('load', () => {
				this.loadPointData();		
			});
		}

		this.initializeMapLayers();

		// When user finish drawing an element
		this.map.on('draw.create', () => {
			this.prepareRenderElements();
		});

		// When user finish drawing an existing element
		this.map.on('draw.update', (e) => {
			this.updateElement(e.features);
		});

		// When user clicks on a draw tool control
		this.map.on('draw.modechange', (e) => {
			const drawnElements = this.draw.getAll();
			if (e.mode === 'draw_line_string' && drawnElements !== null) {
				// Check if a segment is already drawn
				drawnElements.features.forEach( async( item: any, index: any) => {
					if (item.geometry.type === 'LineString' && item.geometry.coordinates.length !== 0) {
						const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDeletePipeline'));
						if (confirm) {
							this.removePipeline();
							drawnElements.features.splice(index, 1);
						} else {
							this.draw.changeMode('simple_select');
						}
					}
				});
			}			
		});

		// Create a popup, but don't add it to the map yet.
		const popup = new Popup({
			closeButton: false,
			closeOnClick: false
		});

		this.map.on('mouseenter', 'feature-points.cold', (e) => {
			this.map.getCanvas().style.cursor = 'pointer';

			// Copy coordinates array.
			// @ts-ignore
			const coordinates = e.features[0].geometry.coordinates.slice();
			// @ts-ignore
			const description = e.features[0].properties.user_description;

			// Ensure that if the map is zoomed out such that multiple
			while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
				coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
			}

			// Populate the popup and set its coordinates based on the feature found.
			popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
		});


		this.map.on('mouseleave', 'feature-points.cold', () => {
			this.map.getCanvas().style.cursor = '';
			popup.remove();
		});

		this.map.on('draw.delete', (e) => {
			// Deleted feature pid
			if (e.features.length > 0) {
				const pid = e.features[0].id;

				// Remove deleted points from list
				for (const item of this.lds) {
					const index = this.lds.indexOf(item);
					if (item.pid === pid) {
						this.ldsToDelete.push(item);
						this.lds.splice(index, 1);
					}
				}

				for (const item of this.cmps) {
					const index = this.cmps.indexOf(item);
					if (item.pid === pid) {
						this.cmpsToDelete.push(item);
						this.cmps.splice(index, 1);
					}
				}

				for (const item of this.features) {
					const index = this.features.indexOf(item);
					if (item.pid === pid) {
						this.featuresToDelete.push(item);
						this.features.splice(index, 1);
					}
				}

				this.segments = [];
			}
		});

		// If the user double clicks a element present a modal to edit it
		this.map.on('dblclick', async(e) => {
			const selected = this.draw.getSelected();
			let pointFound = false;

			if (selected.features.length > 0) {
				const pid = selected.features[0].id;

				const points: {pid: any}[] = this.cmps.concat(this.features as any[]).concat(this.lds as any[]);
				for (let i = 0; i < points.length; i++) {
					const item = points[i];
					if (item.pid === pid) {
						pointFound = true;
						try {
							// LDS
							if (item instanceof LDSPID) {
								const lds = structuredClone(item);
								await Modal.form(Locale.get('lds'), lds, LdsLayoutSimplified, [{
									success: true,
									label: 'update',
									color: 'primary',
									callback: (obj: any): void => {}
								},
								{
									success: false,
									label: 'cancel',
									color: 'primary',
									callback: (obj: any): void => {}
								},
								{
									success: true,
									label: 'open',
									color: 'primary',
									callback: (obj: any): void => {
										App.navigator.navigate('/menu/pipeline-integrity/lds/edit', {ldsUuid: obj.uuid});
									}
								},
								{
									success: true,
									label: 'graph',
									color: 'primary',
									callback: (obj: any): void => {
										App.navigator.navigate('/menu/pipeline-integrity/lds/chart', {ldsUuid: obj.uuid});
									}
								}]);
								Object.assign(item, lds);
								// CMP
							} else if (item instanceof CMPPID) {
								const cmp = structuredClone(item);
								await Modal.form(Locale.get('cmp'), cmp, MotCmpLayoutSimplified, [{
									success: true,
									label: 'update',
									color: 'primary',
									callback: (obj: any): void => {}
								},
								{
									success: false,
									label: 'cancel',
									color: 'primary',
									callback: (obj: any): void => {}
								},
								{
									success: true,
									label: 'open',
									color: 'primary',
									callback: (obj: any): void => {
										App.navigator.navigate('/menu/pipeline-integrity/cmp/edit', {cmpUuid: obj.uuid});
									}
								},
								{
									success: true,
									label: 'acquisitions',
									color: 'primary',
									callback: (obj: any): void => {
										App.navigator.navigate('/menu/pipeline-integrity/acquisition/list', {cmpUuid: obj.uuid, pipelineUuid: this.pipeline.uuid});
									}
								}]);
								Object.assign(item, cmp);

								// Feature
							} else if (item instanceof FeaturePID) {
								const feature = structuredClone(item);
								await Modal.form(Locale.get('feature'), feature, FeatureLayoutSimplified);
								Object.assign(item, feature);
							}
						} catch (err) {}
					}
				}
			}

			// Look for segments close to the pointer
			if (!pointFound) {
				const position = new Geolocation(e.lngLat.lat, e.lngLat.lng);

				const distance: number = Math.pow(1e3 / this.map.getZoom(), 2.0);
				const segmentDistances: SegmentWithDistance[] = [];
				const point: Vector2 = GeolocationUtils.datumsToSpherical(position.latitude, position.longitude);

				// Foreach segment convert geo-coordinates to axis coordinates and get distances between a point and segments
				for (const seg of this.segments) {
					const start = GeolocationUtils.datumsToSpherical(seg.startPoint.latitude, seg.startPoint.longitude);
					const end = GeolocationUtils.datumsToSpherical(seg.endPoint.latitude, seg.endPoint.longitude);

					segmentDistances.push({
						segment: seg,
						startPoint: start,
						endPoint: end,
						distance: MathUtils.distancePointLineSegment(point, [start, end])
					});
				}

				// Get the closest segment to the point clicked by the user.
				const closestSegment = segmentDistances.reduce(function(prev, curr) {
					return prev.distance < curr.distance ? prev : curr;
				});

				// When point is inside tolerance distance.
				if (closestSegment.distance < distance) {
					const segment = structuredClone(closestSegment.segment);
					try {
						await Modal.form(Locale.get('segment'), segment, SegmentLayoutSimplified);
						Object.assign(closestSegment.segment, segment);
					} catch (error) {}
				}
			}
		});
	}

	/**
	 * Load segments, features and CMP from the API.
	 *
	 * Initialize structures required to draw them into the map.
	 */
	public async loadPointData(): Promise<void> {
		// Load segment data from API
		this.segmentsLoaded = [];
		const requestSegments = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.segment.list, null, null, {pipelineUuid: this.pipeline.uuid}, Session.session);
		for (let j = 0; j < requestSegments.response.segments.length; j++) {
			this.segmentsLoaded.push(Segment.parse(requestSegments.response.segments[j]));
		}

		// Create a copy of the array
		this.segments = this.segmentsLoaded.slice();

		// Prepare list of points from segments, segments are connected to each other so the last point of each segment coincides with the first of the next segment.
		const points: any[] = [];
		for (let i = 0; i < this.segments.length; i++) {
			// First point
			if (i === 0) {
				points.push([this.segments[i].startPoint.longitude, this.segments[i].startPoint.latitude]);
			}

			// Draw line point
			points.push([this.segments[i].endPoint.longitude, this.segments[i].endPoint.latitude]);
		}
		this.draw.add({type: 'LineString', coordinates: points});

		// Load CMP from API
		const requestCmp = await Service.fetch(ServiceList.pipelineIntegrity.mot.cmp.list, null, null, {pipelineUuid: this.pipeline.uuid}, Session.session);
		for (let j = 0; j < requestCmp.response.cmps.length; j++) {
			// Parse and draw CMP
			const cmp: CMP = CMP.parse(requestCmp.response.cmps[j]);
			this.draw.add({
				type: 'Feature',
				properties: {
					type: PointType.cmp + '_' + FeatureType.NONE,
					description: Locale.get('cmp') + ' (' + cmp.name + ')'
				},
				geometry: {
					type: 'Point',
					coordinates: [cmp.position.longitude, cmp.position.latitude]
				}
			});

			// Get pid of last drawn element
			const data = this.draw.getAll();
			const lastFeature = data.features.length - 1;
			const pid = data.features[lastFeature].id;

			// Add element to CMP
			const cmpPid = new CMPPID();
			Object.assign(cmpPid, cmp);
			cmpPid.pid = pid;
			this.cmps.push(cmpPid);
		}

		// Load LDS from API
		const requestLds = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.list, null, null, {pipelineUuid: this.pipeline.uuid}, Session.session);
		for (let j = 0; j < requestLds.response.lds.length; j++) {
			// Parse and draw LDS's
			const lds: LDS = LDS.parse(requestLds.response.lds[j]);

			// Get alarm for the LDS
			const alarms = await LDSAlarmHistoryService.listAlarms(lds.uuid);
			let alarmLevel = null;
			for (const alarm of alarms) {
				if (alarm.activated) {
					if (alarmLevel === null || alarm.level > alarmLevel) {
						alarmLevel = alarm.level;
					}
				}
			}
			

			this.draw.add({
				type: 'Feature',
				properties: {
					type: PointType.lds + '_' + FeatureType.NONE,
					description: Locale.get('lds') + ' (' + lds.name + ')',
					alarm: alarmLevel
				},
				geometry: {
					type: 'Point',
					coordinates: [lds.position.longitude, lds.position.latitude]
				}
			});


			// Get pid of last drawn element
			const data = this.draw.getAll();
			const pid = data.features[data.features.length - 1].id;

			// Add element to LDS
			const ldsPid = new LDSPID();
			Object.assign(ldsPid, lds);
			ldsPid.pid = pid;
			ldsPid.alarmLevel = alarmLevel;
			this.lds.push(ldsPid);
		}

		// Load features from API
		const requestFeatures = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.feature.list, null, null, {pipelineUuid: this.pipeline.uuid}, Session.session);
		for (let i = 0; i < requestFeatures.response.features.length; i++) {
			// Parse and draw features
			const feature = Feature.parse(requestFeatures.response.features[i]);
			this.draw.add({
				type: 'Feature',
				properties: {
					type: PointType.feature + '_' + feature.type,
					description: FeatureTypeLabel.get(feature.type) + ' (' + feature.name + ')'
				},
				geometry: {
					type: 'Point',
					coordinates: [feature.position.longitude, feature.position.latitude]
				}
			});

			// Get pid of last drawn element
			const drawData = this.draw.getAll();
			const lastFeature = drawData.features.length - 1;
			const pid = drawData.features[lastFeature].id;

			// Add element to FeaturePID
			const featurePid = new FeaturePID();
			Object.assign(featurePid, feature);
			featurePid.pid = pid;
			this.features.push(featurePid);
		}
	}
	
	/**
	 * Initialize legend to be displayed on map.
	 *
	 * Configure styles and layers.
	 */
	public createLegend(): void {
		const legend: PipelineMapFeatureFilter[] = [];

		// CMP / LDS
		let layers = [Locale.get('cmp'), Locale.get('lds')];
		let colors = [CSSUtils.getVariable('--special-blue-1'), CSSUtils.getVariable('--primary-60')];
		const types = [PointType.cmp, PointType.lds];
		for (let i = 0; i < layers.length; i++) {
			legend.push(new PipelineMapFeatureFilter(layers[i], types[i], FeatureType.NONE, colors[i], true));
		}

		// Features
		layers = [...FeatureTypeLabel].slice(1).map(([key, val]) => {return Locale.get(val);});
		colors = [...FeatureTypeColor].slice(1).map(([key, val]) => {return CSSUtils.getVariable(val);});
		const featureTypes = Object.values(FeatureType).slice(1);
		for (let i = 0; i < layers.length; i++) {
			legend.push(new PipelineMapFeatureFilter(layers[i], PointType.feature, featureTypes[i], colors[i], false));
		}

		this.legend.set(legend);
	}

	/**
	 * Update legend, present the current legend selection.
	 * 
	 * Updates the filters applied in mapbox.
	 */
	public updateLegend(): void {
		const legend = this.legend();
		
		// Create list of features to hide
		const hiddenTypes = [];
		for (let i = 0; i < legend.length; i++) {
			if (!legend[i].enabled) {
				hiddenTypes.push(legend[i].type + '_' + legend[i].featureType);
			}
		}
	
		// Apply filters to hide features selected.
		const filter: any[] = [
			'all',
			['==', '$type', 'Point'],
			['==', 'meta', 'feature'],
			['==', 'active', 'false'],
			['!in', 'user_type', ...hiddenTypes]
		];

		this.map.setFilter('feature-points.cold', filter);
		this.map.setFilter('feature-points.hot', filter);
	}

	/**
	 * Toggle visibility of features on map.
	 *
	 * Set filter to features and strikethrough to legend map on click.
	 */
	public toggleLegendItem(type: PointType, featureType: number): void {
		const legend: PipelineMapFeatureFilter[] = this.legend();
		const item: PipelineMapFeatureFilter = legend.find(function(l: PipelineMapFeatureFilter) {
			return l.type === type && l.featureType === featureType;
		});

		if (!item) {
			throw new Error('Type ' + type + ',' + featureType + ' not available in legend list.');
		}

		item.enabled = !item.enabled;

		this.legend.set(legend);
		this.updateLegend();
	}

	/**
	 * Initialize the mapbox draw object.
	 *
	 * Configure styles used to draw elements into the map and controls.
	 */
	public initializeMapLayers(): void {
		// Draw Options
		this.draw = new MapboxDraw({
			userProperties: true,
			// Instead of showing all the draw tools, show only the line string and delete tools
			displayControlsDefault: false,
			controls: {
				// eslint-disable-next-line camelcase
				line_string: true,
				point: true,
				trash: true
			},
			// Set the draw mode to draw LineStrings by default.
			styles: this.getDrawingStyles()
		});

		// Marker
		this.marker = new Marker();
		this.marker.setLngLat([this.position.longitude, this.position.latitude]);
		this.marker.addTo(this.map); 

		// Render map legend
		this.createLegend();

		// Map search box
		this.map.addControl(new MapboxGeocoder({
			accessToken: Environment.MAPBOX_TOKEN,
			// @ts-ignore
			mapboxgl: this.map
		}), 'bottom-left');

		// Style switch controls
		const styles: MapboxStyleDefinition[] = [];
		MapStylesLabel.forEach(function(value, key) {
			styles.push({
				title: Locale.get(MapStylesLabel.get(key)),
				uri: key
			});
		});

		this.map.addControl(this.draw);
		this.map.addControl(new MapboxStyleSwitcherControl(styles), 'top-right');
		this.map.addControl(new NavigationControl(), 'top-right');
		this.map.addControl(new ScaleControl({maxWidth: 80, unit: 'metric'}));
		this.map.addControl(new ScaleControl({maxWidth: 80, unit: 'imperial'}));

		this.map.on('load', () => {
			this.updateLegend();
		});
	}

	/**
	 * Get drawing styles and rules for the pipeline and points.
	 * 
	 * @returns Drawing style to be applied.
	 */
	public getDrawingStyles(): any[] {
		const colorsFilter = [
			'case',
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.WELD], CSSUtils.getVariable('--special-yellow-2'), 
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.SUPPORT], CSSUtils.getVariable('--special-green-3'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.FLANGE], CSSUtils.getVariable('--special-purple-1'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.TEE_BRANCH], CSSUtils.getVariable('--special-green-1'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.ELBOW], CSSUtils.getVariable('--special-orange-1'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.BRACE], CSSUtils.getVariable('--special-red-2'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.DEFECT], CSSUtils.getVariable('--special-pink-1'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.INDICATION], CSSUtils.getVariable('--special-green-4'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.VALVES], CSSUtils.getVariable('--special-red-1'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.FEATURE_GENERIC], CSSUtils.getVariable('--special-yellow-1'),
			['==', ['get', 'user_type'], PointType.cmp + '_' + FeatureType.NONE], CSSUtils.getVariable('--special-blue-1'),
			['==', ['get', 'user_type'], PointType.lds + '_' + FeatureType.NONE], CSSUtils.getVariable('--primary-60'),
			CSSUtils.getVariable('--primary-60')
		];

		const borderColor = [
			'case',
			['==', ['get', 'user_alarm'], LDSAlarmLevel.HIGH], CSSUtils.getVariable('--error-normal'),
			['==', ['get', 'user_alarm'], LDSAlarmLevel.MEDIUM], CSSUtils.getVariable('--special-orange-1'),
			['==', ['get', 'user_alarm'], LDSAlarmLevel.LOW], CSSUtils.getVariable('--warning-normal'),
			CSSUtils.getVariable('--primary-60')
		];


		const borderWidth = [
			'case',
			['==', ['get', 'user_alarm'], LDSAlarmLevel.HIGH], 5,
			['==', ['get', 'user_alarm'], LDSAlarmLevel.MEDIUM], 4,
			['==', ['get', 'user_alarm'], LDSAlarmLevel.LOW], 3,
			0
		];

		return [
			// Set the line style
			{
				id: 'gl-draw-line',
				type: 'line',
				filter: [
					'all',
					['==', '$type', 'LineString']
				],
				layout: {
					'line-cap': 'round',
					'line-join': 'round'
				},
				paint: {
					'line-color': this.pipeline.color || CSSUtils.getVariable('--primary-60'),
					'line-dasharray': [0.2, 2],
					'line-width': 4,
					'line-opacity': 0.7
				}
			},
			// Style the pipeline vertex points.
			{
				id: 'gl-draw-vertex',
				type: 'circle',
				filter: [
					'all',
					['==', '$type', 'Point'],
					['!=', 'meta', 'feature']
				],
				paint: {
					'circle-radius': 7,
					'circle-color': this.pipeline.color || CSSUtils.getVariable('--primary-60'),
					'circle-stroke-color': CSSUtils.getVariable('--light'),
					'circle-stroke-width': 3
				}
			},
			// Style applied to active items (being editted)
			{
				id: 'highlight-active-points',
				type: 'circle',
				filter: [
					'all',
					['==', '$type', 'Point'],
					['==', 'meta', 'feature'],
					['==', 'active', 'true']],
				paint: {
					'circle-color': colorsFilter,
					'circle-radius': 7,
					'circle-stroke-color': CSSUtils.getVariable('--light'),
					'circle-stroke-width': 3
				}
			},
			// Style applied to features
			{
				id: 'feature-points',
				type: 'circle',
				filter: [
					'all',
					['==', '$type', 'Point'],
					['==', 'meta', 'feature'],
					['==', 'active', 'false']],
				paint: {
					'circle-color': colorsFilter,
					'circle-radius': 7,
					'circle-stroke-color': borderColor,
					'circle-stroke-width': borderWidth
				}
			}
		];
	}

	/**
	 * Method called when the user finished creating a new segments, cmp or features.
	 */
	public async prepareRenderElements(): Promise<void> {
		// Compute distance tolerance based on map zoom
		const snapTolerance = Math.pow(1e3 / this.map.getZoom(), 2.0);

		// Last element drawn
		let drawData = this.draw.getAll();
		const lastFeature = drawData.features.length > 0 ? drawData.features[drawData.features.length - 1] : null;
		if (!lastFeature) {
			throw new Error('No feature available.');
		}

		// @ts-ignore
		const coords = lastFeature.geometry.coordinates;
		const type = lastFeature.geometry.type;
		const pid = lastFeature.id;

		// When segments were drawn
		if (type === 'LineString') {
			const segmentCoordinatesPoints: any[] = [];
			// Get coordinates of segments
			for (const item of drawData.features) {
				if (item.geometry.type === 'LineString') {
					item.geometry.coordinates.forEach((cord: any) => {
						segmentCoordinatesPoints.push(cord);
					});
				}
				this.segments = this.createSegments(segmentCoordinatesPoints, this.segmentsLoaded, this.pipeline.uuid);
			}

		// When a CMP/feature was drawn
		} else if (type === 'Point') {
			const segmentCoordinatesPoints: any[] = [];
			const segmentDistances: {startPoint: Vector2, endPoint: Vector2, distance: number}[] = [];
			let closestPoint: Geolocation;

			// Check if map already has a segment drawn
			for (const item of drawData.features) {
				if (item.geometry.type === 'LineString') {
					item.geometry.coordinates.forEach((cord: any) => {
						segmentCoordinatesPoints.push(cord);
					});

					// Get all segments
					const segments = this.createSegments(segmentCoordinatesPoints, this.segmentsLoaded, this.pipeline.uuid);
					const point: Vector2 = GeolocationUtils.datumsToSpherical(coords[1], coords[0]);

					// Foreach segment convert geo-coordinates to axis coordinates and get distances between a point and segments
					for (const seg of segments) {
						const start = GeolocationUtils.datumsToSpherical(seg.startPoint.latitude, seg.startPoint.longitude);
						const end = GeolocationUtils.datumsToSpherical(seg.endPoint.latitude, seg.endPoint.longitude);

						segmentDistances.push({
							startPoint: start,
							endPoint: end,
							distance: MathUtils.distancePointLineSegment(point, [start, end])
						});
					}

					// Get the closest segment to the point
					const closestSegment = segmentDistances.reduce(function(prev, curr) {
						return prev.distance < curr.distance ? prev : curr;
					});

					// When point is outside tolerance distance.
					if (closestSegment.distance > snapTolerance) {
						this.draw.delete(pid as string);
						drawData = this.draw.getAll();
						Modal.alert(Locale.get('error'), Locale.get('errorSnapTolerance'));
					} else {
						// Closest point on the line
						const closestPointXY: Vector2 = MathUtils.getClosesPointOnLine(point, [closestSegment.startPoint, closestSegment.endPoint]);
						closestPoint = GeolocationUtils.sphericalToDatums(closestPointXY.x, closestPointXY.y);

						const option = {type: PointType.cmp};

						try {
							await Modal.form(Locale.get('chooseElementModal'), option, PipelineMapPointLayout);

							if (option.type === PointType.cmp) {
								const cmpPid = new CMPPID();
								cmpPid.position = new Geolocation(closestPoint.latitude, closestPoint.longitude);
								cmpPid.pid = pid;

								await Modal.form(Locale.get('cmp'), cmpPid, MotCmpLayoutSimplified);

								// Update coordinates of element drawn
								this.draw.add({id: pid, type: 'Feature', properties: {type: PointType.cmp + '_' + FeatureType.NONE}, geometry: {type: 'Point', coordinates: [closestPoint.longitude, closestPoint.latitude]}});
								this.cmps.push(cmpPid);

							} else if (option.type === PointType.lds) {
								const ldsPid = new LDSPID();
								ldsPid.position = new Geolocation(closestPoint.latitude, closestPoint.longitude);
								ldsPid.pipelineUuid = this.pipeline.uuid;
								ldsPid.pid = pid;

								delete ldsPid.createdAt;
								delete ldsPid.updatedAt;

								await Modal.form(Locale.get('lds'), ldsPid, LdsLayoutSimplified);

								// Update coordinates of element drawn
								this.draw.add({id: pid, type: 'Feature', properties: {type: PointType.lds + '_' + FeatureType.NONE}, geometry: {type: 'Point', coordinates: [closestPoint.longitude, closestPoint.latitude]}});
								this.lds.push(ldsPid);

							} else if (option.type === PointType.feature) {
								const featurePid = new FeaturePID();
								featurePid.position = new Geolocation(closestPoint.latitude, closestPoint.longitude);
								featurePid.pid = pid;

								await Modal.form(Locale.get('feature'), featurePid, FeatureLayoutSimplified);

								// Update coordinates and color of element drawn
								this.draw.add({id: pid, type: 'Feature', properties: {type: PointType.feature + '_' + featurePid.type}, geometry: {type: 'Point', coordinates: [closestPoint.longitude, closestPoint.latitude]}});
								this.features.push(featurePid);
							}
							drawData = this.draw.getAll();
						} catch (error) {
							// On cancel remove point drawn and update dataD
							this.draw.delete(pid as string);
							drawData = this.draw.getAll();
						}
					}
				}
			}
		}
	}

	/**
	 * Update element after operations (e.g. move).
	 *
	 * @param features - Features that have been edited and need to be updated.
	 */
	public updateElement(features: Feature[]): void {
		// Compute distance tolerance based on map zoom
		const snapTolerance = Math.pow(1e3 / this.map.getZoom(), 2.0);

		if (features.length > 0) {
			const feature: any = features[0];

			const coords = feature.geometry.coordinates;
			const type = feature.geometry.type;
			const pid = feature.id;

			// When segments were drawn
			if (type === 'LineString') {
				const segmentCoordinatesPoints: any[] = [];
				// Get coordinates of segments
				const drawData = this.draw.getAll();
				for (const item of drawData.features) {
					if (item.geometry.type === 'LineString') {
						item.geometry.coordinates.forEach((cord: any) => {
							segmentCoordinatesPoints.push(cord);
						});
					}
					this.segments = this.createSegments(segmentCoordinatesPoints, this.segmentsLoaded, this.pipeline.uuid);
				}
			// When a CMP/feature was drawn
			} else if (type === 'Point') {
				const segmentCoordinatesPoints: any[] = [];
				const segmentDistances: {startPoint: Vector2, endPoint: Vector2, distance: number}[] = [];

				// Closest point if any was found
				let closestPoint: Geolocation = null;

				// Look for the closest point in the map features
				const drawnElements = this.draw.getAll();
				for (const item of drawnElements.features as any[]) {
					if (item.geometry.type === 'LineString') {
						for (const cord of item.geometry.coordinates) {
							segmentCoordinatesPoints.push(cord);
						}

						// Get all segments
						const segments = this.createSegments(segmentCoordinatesPoints, this.segmentsLoaded, this.pipeline.uuid);

						// Foreach segment convert geo-coordinates to axis coordinates and get distances between a point and segments
						const point: any = GeolocationUtils.datumsToSpherical(coords[1], coords[0]);
						for (const seg of segments) {
							const start = GeolocationUtils.datumsToSpherical(seg.startPoint.latitude, seg.startPoint.longitude);
							const end = GeolocationUtils.datumsToSpherical(seg.endPoint.latitude, seg.endPoint.longitude);
							segmentDistances.push({
								startPoint: start,
								endPoint: end,
								distance: MathUtils.distancePointLineSegment(point, [start, end])
							});
						}

						// Get the closest segment to the point
						const closestSegment = segmentDistances.reduce(function(prev, curr) {
							return prev.distance < curr.distance ? prev : curr;
						});

						// When point is outside tolerance distance.
						if (closestSegment.distance > snapTolerance) {
							Modal.alert(Locale.get('error'), Locale.get('errorSnapTolerance'));
							continue;
						}

						// Get point on the line
						const closestPointXY: Vector2 = MathUtils.getClosesPointOnLine(point, [closestSegment.startPoint, closestSegment.endPoint]);
						closestPoint = GeolocationUtils.sphericalToDatums(closestPointXY.x, closestPointXY.y);
					}
				}

				// Update coordinates of point element
				const points: {pid: any, position: Geolocation}[] = this.cmps.concat(this.features as any[]).concat(this.lds as any[]);
				for (let i = 0; i < points.length; i++) {
					if (points[i].pid === pid) {
						if (closestPoint) {
							points[i].position = new Geolocation(closestPoint.latitude, closestPoint.longitude);
						}

						feature.geometry.coordinates = [points[i].position.longitude, points[i].position.latitude];
						this.draw.delete(feature.id);
						this.draw.add(feature);
					}
				}

			}
		}
	}


	/**
	 * Transforms all segment points drawn in the map into segments(Ex: 3 points = 2 Segments).
	 *
	 * @param points - Array of points of all the segments points drawn.
	 * @param segmentsLoad - Indicates if segments were loaded from API.
	 * @param createdPipelineUuid - UUID of the pipeline created.
	 * @returns An array of Segments.
	 */
	public createSegments(points: number[][], segmentsLoad: Segment[], createdPipelineUuid: any): Segment[] {
		// On create
		if (segmentsLoad.length === 0) {
			const segments: Segment[] = [];
			const geoPoints: Geolocation[] = points.map((c: number[]) => {
				return Geolocation.fromArray(c);
			});

			// No segments drawn , first time its drawn
			if (this.segments.length === 0) {
				for (let i = 1; i < geoPoints.length;i++) {
					const segment = new Segment();
					segment.uuid = generateUUID();
					segment.name = 'segment_' + MathUtils.formatNumber(i, 2, 0);
					segment.pipelineUuid = createdPipelineUuid;
					segment.startPoint = geoPoints[i - 1];
					segment.endPoint = geoPoints[i];
					segments.push(segment);	
				}
			// There are already segments drawn
			} else {
				// Get the number of starting points to know if segments were removed
				const startPoints: Geolocation[] = [];
				for (let i = 1; i < geoPoints.length;i++) {
					startPoints.push(geoPoints[i - 1]);
				}

				// As we validate if there are less segments checking starting points, we need to check if the start point has been removed or just moved to other position when segments were removed
				if (this.segments.length !== startPoints.length) {
					for (let i = 1; i < geoPoints.length;i++) {
						for (let x = 0; x < this.segments.length; x++) {
							if (geoPoints[i - 1].latitude === this.segments[x].startPoint.latitude && geoPoints[i - 1].longitude === this.segments[x].startPoint.longitude) {
								segments.push(this.createSegment(this.segments[x], geoPoints[i - 1], geoPoints[i]));
							}
						}
					}
				// When segments were just moved
				} else {
					for (let i = 1; i < geoPoints.length;i++) {
						segments.push(this.createSegment(this.segments[i - 1], geoPoints[i - 1], geoPoints[i]));
					}
				}
			}

			return segments;
		// On edit
		} else {
			const geoPoints: Geolocation[] = points.map((c: number[]) => {
				return Geolocation.fromArray(c);
			});

			const segments: Segment[] = [];
			const count: number = geoPoints.length - 1;

			// Same number of segments 
			if (count === this.segmentsLoaded.length) {	
				let k = 0;

				// segments were just updated(keep data).
				if (this.segments.length !== 0) {
					for (let i = 1; i < geoPoints.length;i++) {
						segments.push(this.createSegment(this.segments[k], geoPoints[i - 1], geoPoints[i]));
						k++;
					}
				// segments were deleted, and created again(discard data)
				} else {
					for (let i = 1; i < geoPoints.length;i++) {
						const segment = Segment.parse(this.segmentsLoaded[k]);
						segment.name = 'segment_' + MathUtils.formatNumber(i, 2, 0);
						segment.startPoint = geoPoints[i - 1];
						segment.endPoint = geoPoints[i];
						segments.push(segment);
						k++;
					}
				}

				return segments;

			// More segments
			} else if ( count > this.segmentsLoaded.length) {
				const diff = Math.abs(count - this.segmentsLoaded.length);
				let k = 0;

				// Reuse loaded segments UUID
				for (let i = 1; i < geoPoints.length - diff;i++) {
					const segment = Segment.parse(this.segmentsLoaded[k]);
					segment.name = 'segment_' + MathUtils.formatNumber(i, 2, 0);
					segment.startPoint = geoPoints[i - 1];
					segment.endPoint = geoPoints[i];
					segments.push(segment);
					k++;
				}

				// New segments, generate UUID's
				for (let j = 0; j < diff; j++) {
					const segment = new Segment();
					segment.uuid = generateUUID();
					segment.name = 'segment_' + MathUtils.formatNumber(k + j + 1, 2, 0);
					segment.pipelineUuid = createdPipelineUuid;
					segment.startPoint = geoPoints[k + j];
					segment.endPoint = geoPoints[k + j + 1];
					segments.push(segment);
				}
			// Less segments
			} else {

				// All segments were deleted, just reuse UUID of loaded segments
				if (this.segments.length === 0) {
					for (let i = 1; i < geoPoints.length;i++) {
						const segment = Segment.parse(this.segmentsLoaded[i - 1]);
						segment.name = 'segment_' + MathUtils.formatNumber(i, 2, 0);
						segment.startPoint = geoPoints[i - 1];
						segment.endPoint = geoPoints[i];
						segments.push(segment);
					}
				} else {
					// As we validate if there are less segments checking starting points, we need to check if the start point has been removed or just moved to other position
					const startPoints: Geolocation[] = [];

					// Get starting points to know if segments were removed
					for (let i = 1; i < geoPoints.length;i++) {
						startPoints.push(geoPoints[i - 1]);
					}

					// When segments were removed 
					if (this.segments.length !== startPoints.length) {
						for (let i = 1; i < geoPoints.length;i++) {
							for (let x = 0; x < this.segments.length; x++) {
								if (geoPoints[i - 1].latitude === this.segments[x].startPoint.latitude && geoPoints[i - 1].longitude === this.segments[x].startPoint.longitude) {
									segments.push(this.createSegment(this.segments[x], geoPoints[i - 1], geoPoints[i]));
								}
							}
						}
					// When segments were just moved
					} else {
						for (let i = 1; i < geoPoints.length;i++) {
							segments.push(this.createSegment(this.segments[i - 1], geoPoints[i - 1], geoPoints[i]));
						}
					}
				}
			}
			return segments;
		}
	}

	/**
	 * Get all connections between segments.
	 *
	 * @param segments - List of Segments.
	 * @returns An array of connections objects.
	 */
	 public getSegmentConnections(segments: Segment[]): Connection[] {	
		const connections = [];	
		for (let j = 0; j < segments.length; j++) {	
			for (let k = 0; k < segments.length; k++) {

				const segmentPairs: Connection = {segmentAUuid: null, segmentBUuid: null};

				const [{
					endPoint: {
						latitude: prevEndPointLat,
						longitude: prevEndPointLong
					},
					uuid: previousUuid
				}, {
					startPoint: {
						latitude: currStartPointLat,
						longitude: currStartPointLong
					},
					uuid: currentUuid
				}] = [segments[j], segments[k]];

				if (prevEndPointLat === currStartPointLat && prevEndPointLong === currStartPointLong) {
					segmentPairs.segmentAUuid = previousUuid;
					segmentPairs.segmentBUuid = currentUuid;
					connections.push(segmentPairs);
				}
			}
		}

		return connections;
	}

	/**
	 * Update map position.
	 */
	 public updatePosition(location: Geolocation): void {
		this.position = location;

		if (this.map) {
			this.map.flyTo({center: [location.longitude, location.latitude]});
		}

		this.marker.setLngLat([location.longitude, location.latitude]);
	}


	/**
	 * Create a segment
	 *
	 * @param segment - Segment object data.
	 * @param startPoint - Coordinates of the segment start point.
	 * @param endPoint - Coordinates of the segment end point.
	 * @returns a Segment.
	 */
	public createSegment(segment: Segment, startPoint: Geolocation, endPoint: Geolocation): Segment {
		const seg = Segment.parse(segment);
		seg.startPoint = startPoint;
		seg.endPoint = endPoint;
		return seg;
	}
	
	/**
	 * Remove pipeline from map.
	 */
	public removePipeline(): void {
		const data = this.draw.getAll();
		const pids = [];

		// ID of the added template empty feature
		const lid = data.features[data.features.length - 1].id;

		data.features.forEach((f: any) => {
			if (f.geometry.type === 'LineString' && f.id !== lid) {
				pids.push(f.id);
			}
		});

		this.segments = [];
		this.draw.delete(pids);
	}
}
