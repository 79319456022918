import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EChartsType, init, use} from 'echarts/core';
import {LineChart, ScatterChart} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {
	DataZoomComponent,
	GridComponent,
	LegendComponent,
	MarkLineComponent,
	TimelineComponent,
	TitleComponent,
	ToolboxComponent,
	TooltipComponent
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import {EChartsOption} from 'echarts';
import {TranslateModule} from '@ngx-translate/core';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {FormatDatePipe} from 'src/app/pipes/format-date.pipe';
import {LDSAlarm} from 'src/app/models/pipeline-integrity/leak-detection/lds-alarm';
import {CSSUtils} from 'src/app/utils/css-utils';
import {LDSAlarmLevel} from 'src/app/models/pipeline-integrity/leak-detection/lds-alarm-level';
import {LDSLastDataResponse, LDSService} from 'src/app/modules/pipeline-integrity/services/lds.service';
import {subHours} from 'date-fns';
import {InputOptionsMultipleLazyPageRequest, InputOptionsMultipleBatchRequest} from '../../../../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {App} from '../../../../../../app';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {UUID} from '../../../../../../models/uuid';
import {LDS} from '../../../../../../models/pipeline-integrity/leak-detection/lds';
import {LdsLayout} from '../../lds-layout';
import {StyleManager} from '../../../../../../theme/style-manager';
import {Locale} from '../../../../../../locale/locale';
import {EventManager} from '../../../../../../utils/event-manager';
import {ResizeDetector} from '../../../../../../utils/resize-detector';
import {UnoOptionsList} from '../../../../../../components/uno/uno-options-list/uno-options-list.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';

// Register required components for echarts
use([TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent, TimelineComponent, CanvasRenderer, LegendComponent, UniversalTransition, ScatterChart, MarkLineComponent, ToolboxComponent]);

/**
 * Function to calculate the minimum value of the Y axis
 * 
 * @param value - Chart value.
 */
const yScaleValueMin = function(value): number {
	return value.min - (value.max - value.min) * 0.2;
};

/**
 * Function to calculate the maximum value of the Y axis
 * 
 * @param value - Chart value.
 */
const yScaleValueMax = function(value): number {
	return value.max + (value.max - value.min) * 0.2;
};

@Component({
	selector: 'lds-graph-page',
	templateUrl: 'lds-chart.page.html',
	standalone: true,
	imports: [
		UnoContentComponent,
		UnoOptionsList,
		TranslateModule,
		UnoFilterBarComponent
	]
})

export class LDSChartPage extends ScreenComponent implements OnInit, OnDestroy {
	public app: any = App;

	public layout: any = LdsLayout;

	public userPermissions: any = UserPermissions;

	public session: any = Session;

	public selfStatic: any = LDSChartPage;

	public permissions = [UserPermissions.PIPELINE_INTEGRITY];

	/**
	 * Div to place the chart in.
	 */
	@ViewChild('chart', {static: true})
	public chartDiv: ElementRef = null;

	/**
	 * EChart chart object.
	 */
	public chart: EChartsType = null;

	/**
	 * Chart configuration options.
	 */
	public chartOptions: EChartsOption = {};

	/**
	 * Event manager used to resize the canvas area on window resize.
	 */
	public manager: EventManager = new EventManager();

	/**
	 * LDS to visualize data.
	 */
	public lds: LDS = null;

	/**
	 * Data to visualize
	 */
	public data: any = null;

	/**
	 * Resize detector.
	 */
	public resizeDetector: ResizeDetector = null;

	/**
	 * Alarms applied to the LDS.
	 */
	public alarms: LDSAlarm[] = [];

	/**
	 * Options to use on the filter bar.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS_LAZY,
			attribute: 'timestamp',
			label: 'timestamp',
			identifierAttribute: 'timestamp',
			multiple: false,
			fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> => {
				const filters = {
					search: request.search,
					count: request.count,
					offset: request.from,
					searchFields: ['[timestamp]'],
					sortField: 'timestamp',
					sortDirection: 'DESC'
				};

				const req = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.data.list.timestamp, null, null, {ldsUuid: App.navigator.getData().ldsUuid, filters: filters}, Session.session, true);
				
				for (let i = 0; i < req.response.timestamps.length; i++) {
					req.response.timestamps[i] = {timestamp: new Date(req.response.timestamps[i]).toISOString()};
				}

				return {options: req.response.timestamps, hasMore: req.response.hasMore, id: req.id};
			},
			fetchOptionsBatch: function(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> {
				return Promise.resolve({options: []});
			},
			getOptionText: function(option: any): string {
				let timestamp = option.timestamp;
				timestamp = subHours(new Date(timestamp), -(new Date(timestamp).getTimezoneOffset() / 60));

				return FormatDatePipe.formatDateTime(timestamp);
			}
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * LDS Timestamp to be considered.
		 */
		timestamp: ''
	}, LDSChartPage.filterOptions);

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.lds = null;

		App.navigator.setTitle('graph');

		const data = App.navigator.getData();
		if (!data || !data.ldsUuid) {
			App.navigator.pop();
			return;
		}

		this.lds = await LDSService.get(data.ldsUuid);

		this.initChart();

		this.data = await this.loadLastLDSData(data.ldsUuid);
		this.alarms = await LDSService.listAlarms(data.ldsUuid);

		this.updateChartdata(this.data, this.alarms);

		this.resizeDetector = new ResizeDetector(this.chartDiv.nativeElement, () => {
			this.chart.resize();
		});
	}

	public ngOnDestroy(): void {
		if (this.chart) {
			this.chart.dispose();
			this.chart = null;
		}

		this.manager.destroy();
		this.resizeDetector.destroy();
	}

	public initChart(): void {
		this.chart = init(this.chartDiv.nativeElement, StyleManager.theme.echarts, {
			width: null,
			height: null,
			renderer: 'canvas',
			locale: Locale.code
		});

		// Add options to chart
		this.chartOptions = {
			title: {},
			tooltip: {trigger: 'axis'},
			legend: {},
			backgroundColor: 'transparent',
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			},
			xAxis: {
				type: 'value',
				min: 'dataMin',
				max: 'dataMax',
				axisLabel: {formatter: function(value: any): string {return value.toFixed(2);}}
			},
			yAxis: {
				type: 'value',
				min: yScaleValueMin,
				max: yScaleValueMax,
				axisLabel: {formatter: function(value: any): string {return value.toFixed(2);}}
			},
			dataZoom: [
				{
				  type: 'slider',
				  xAxisIndex: 0,
				  filterMode: 'none'
				},
				{
				  type: 'slider',
				  yAxisIndex: 0,
				  filterMode: 'none'
				},
				{
				  type: 'inside',
				  xAxisIndex: 0,
				  filterMode: 'none'
				},
				{
				  type: 'inside',
				  yAxisIndex: 0,
				  filterMode: 'none'
				}
			  ],
			series: []
		};

		this.chart.setOption(this.chartOptions);
	}

	/**
	 * Load last graph data for LDS.
	 *
	 * @param uuid - LDS UUID
	 * @returns Last reading available for the LDS:
	 */
	public async loadLastLDSData(uuid: UUID): Promise<any> {
		const data = await LDSService.lastData(uuid);

		LDSChartPage.filters.timestamp = new Date(data.timestamp).toISOString();

		return data;
	}

	/**
	 * Load graph data for LDS selected on dropdown.
	 *
	 * @param item - The item selected on dropdown
	 * @param uuid - LDS UUID
	 */
	public async loadSelectedLDSData(item: {timestamp: string}, uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.data.get, null, null, {ldsUuid: uuid, startDate: item.timestamp, endDate: item.timestamp}, Session.session);
		const data = request.response;

		this.updateChartdata(data[0], this.alarms);		
	}

	/**
	 * Update chart data.
	 *
	 * @param data - Data of the LDS.
	 */
	public updateChartdata(data: LDSLastDataResponse = null, alarms: LDSAlarm[] = null): void {
		const series: any = [];
		const channelsLegend: any = [];
		const markLine: any = [];

		if (data !== null) {
			for (let i = 0; i < data.channels.length; i++) {
				const values = data.channels[i].data;
				const y = [];
				for (let j = 0; j < values.length; j++) {
					y.push(values[j]);
				}
	
				series.push({
					type: 'line',
					data: y,
					name: 'channel_' + data.channels[i].index,
					symbol: 'none',
					sampling: 'lttb,',
					tooltip: {valueFormatter: (value: number) => {return Math.round(value * 100) / 100;}}
				});
				channelsLegend.push('channel_' + data.channels[i].index);
	
			}
		}

		// Reset axis
		// @ts-ignore
		this.chartOptions.yAxis.min = yScaleValueMin;
		// @ts-ignore
		this.chartOptions.yAxis.max = yScaleValueMax;

		if (alarms !== null) {
			for (const alarm of alarms) {
				const alarmColor: Map<LDSAlarmLevel, string> = new Map([
					[LDSAlarmLevel.LOW, CSSUtils.getVariable('--success-tint-light')],
					[LDSAlarmLevel.MEDIUM, CSSUtils.getVariable('--warning-normal')],
					[LDSAlarmLevel.HIGH, CSSUtils.getVariable('--error-tint-light')]
				]);

				const addMarkLine = function(al: LDSAlarm, value: number): void {
					markLine.push({
						name: al.description,
						yAxis: value,
						lineStyle: {color: alarmColor.get(al.level), type: 'solid', width: 2},
						label: {position: 'insideEndTop', show: 'true', formatter: '{b}'}
					});
				};

				// Check range value(max-min)
				if (alarm.range.max !== undefined && alarm.range.min !== undefined) {
					addMarkLine(alarm, alarm.range.max);
					addMarkLine(alarm, alarm.range.min);
				} else if (alarm.range.max !== undefined) {
					addMarkLine(alarm, alarm.range.max);
				} else if (alarm.range.min !== undefined) {
					addMarkLine(alarm, alarm.range.min);
				}

				// @ts-ignore
				if (alarm.range.min !== undefined && (this.chartOptions.yAxis.min === yScaleValueMin || this.chartOptions.yAxis.min > alarm.range.min)) {
					// @ts-ignore
					this.chartOptions.yAxis.min = alarm.range.min;
				}

				// @ts-ignore
				if (alarm.range.max !== undefined && (this.chartOptions.yAxis.max === yScaleValueMax || this.chartOptions.yAxis.max < alarm.range.max)) {
					// @ts-ignore
					this.chartOptions.yAxis.max = alarm.range.max;
				}

			}

			series.push({
				type: 'line',
				markLine: {data: markLine, symbol: ['none', 'none']}
			});
		}

		// @ts-ignore
		this.chartOptions.legend.data = channelsLegend;
		this.chartOptions.series = series;
		this.chart.setOption(this.chartOptions);
	}
}
