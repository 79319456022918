import {Component, OnInit, signal, ViewEncapsulation, WritableSignal} from '@angular/core';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {App} from 'src/app/app';
import {Dashboard} from 'src/app/models/dashboards/dashboard';
import {NgStyle} from '@angular/common';
import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {Locale} from 'src/app/locale/locale';
import {generateUUID} from 'src/app/models/uuid';
import {WidgetSize} from 'src/app/models/dashboards/widget-size';
import {AssetDistributionType} from 'src/app/modules/dashboard-new/data/widgets/asset-distribution-widget/asset-distribution-type';
import {Environment} from 'src/environments/environment';
import {TranslateModule} from '@ngx-translate/core';
import {Modal} from 'src/app/modal';
import {IonicModule} from '@ionic/angular';
import {DashboardService} from '../../services/dashboard.service';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoNoDataComponent} from '../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoIconComponent} from '../../../../components/uno/uno-icon/uno-icon.component';
import {ButtonColor, ButtonType, UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {DashboardBaseLayout} from '../dashboard-layout';
import {UnoDashboardComponent} from '../../components/dashboard/dashboard.component';
import {WidgetsMap} from '../../data/widgets-map';
import {Widget} from '../../data/widget-interface';
import {DashboardWidget} from '../../../../models/dashboards/widget';

@Component({
	selector: 'dashboard-edit-page',
	templateUrl: 'dashboard-edit.page.html',
	styleUrls: ['./dashboard-edit.page.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UnoDashboardComponent, UnoContentComponent, UnoNoDataComponent, NgStyle, UnoIconComponent, UnoButtonComponent, TranslateModule, IonicModule]
})

export class DashboardEditPage extends ScreenComponent implements OnInit {
	public app = App;

	public buttonColor = ButtonColor;

	public buttonType = ButtonType;

	/**
	 * The widgets to display on the sidebar.
	 */
	public widgets: {widgetType: WidgetTypes, label: string, distribution?: AssetDistributionType}[] = [];

	/**
	 * The dashboard to display.
	 */
	public dashboard: Dashboard = null;

	/**
	 * If the dashboard is editable.
	 */
	public editable: WritableSignal<boolean> = signal(false);

	/**
	 * If the dashboard is in the preview mode.
	 */
	public preview: WritableSignal<boolean> = signal(false);

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		if (!data.uuid) {
			throw new Error('Missing required data for the screen, uuid is required.');
		}

		if (data.editable) {
			this.editable.set(true);
		}
		
		this.dashboard = await DashboardService.get(data.uuid);

		App.navigator.setTitle(Locale.get('dashboard'));

		for (const widget of WidgetsMap) {
			if (widget[0] === WidgetTypes.DEMO_WIDGET && Environment.PRODUCTION) {
				continue;
			} else if (widget[0] === WidgetTypes.ASSET_DISTRIBUTION_BY_TYPE_SUBTYPE) {
				this.widgets.push({widgetType: widget[0], label: Locale.get('assetDistributionTypeWidget'), distribution: AssetDistributionType.TYPE});
				this.widgets.push({widgetType: widget[0], label: Locale.get('assetDistributionSubtypeWidget'), distribution: AssetDistributionType.SUBTYPE});
				continue;
			}

			this.widgets.push({widgetType: widget[0], label: widget[1]?.label ? Locale.get(widget[1].label) : null});
		}
	}

	/**
	 * Adds a widget to the current dashboard.
	 * 
	 * @param widgetType - The type of widget to add.
	 * @param distribution - The distribution type for the widget, only used for the ASSET_DISTRIBUTION_BY_TYPE_SUBTYPE Widget.
	 */
	public async addWidget(widgetType: WidgetTypes, distribution: AssetDistributionType = null): Promise<void> {
		const widget: Widget = WidgetsMap.get(widgetType).widget;

		// @ts-ignore
		const widgetData: any = new widget.config();

		let widgetName: string = Locale.get(WidgetsMap.get(widgetType)?.label ? WidgetsMap.get(widgetType)?.label : '');
		if (distribution) {
			widgetName = distribution === AssetDistributionType.SUBTYPE ? Locale.get('assetDistributionSubtypeWidget') : Locale.get('assetDistributionTypeWidget');
			widgetData.showOtherAssets = distribution === AssetDistributionType.SUBTYPE;
			widgetData.distribution = distribution;
		}

		const dashboardWidget = new DashboardWidget();

		Object.assign(dashboardWidget, {
			name: widgetName,
			type: widgetType,
			data: widgetData,
			description: '',
			size: WidgetSize.SMALL,
			index: this.dashboard.widgets.length,
			pinned: true,
			dashboardUuid: this.dashboard.uuid,
			uuid: generateUUID(),
			updatedAt: new Date(),
			createdAt: new Date()
		});

		this.dashboard.widgets = this.dashboard.widgets.concat(dashboardWidget);

		await DashboardService.update(this.dashboard);
	}

	/**
	 * Opens the edit modal for the current dashboard.
	 */
	public async editDashboard(): Promise<void> {
		await Modal.form(Locale.get('edit'), this.dashboard, DashboardBaseLayout);

		const response = await DashboardService.list({}, true);

		// If the index already exists, show a confirmation message to insert the existing dashboard.
		for (const d of response.dashboards) {
			if (this.dashboard.index === d.index) {
				const confirm = await Modal.confirm(Locale.get('warning'), Locale.get('indexAlreadyExists'));
				if (confirm) {
					break;
				}
				return;
			}
		}

		await DashboardService.update(this.dashboard);
	}

	/**
	 * Deletes the current dashboard.
	 */
	public async deleteDashboard(): Promise<void> {
		const confirm: boolean = await Modal.confirm(Locale.get('delete'), Locale.get('confirmDelete'));
		if (confirm) {
			await DashboardService.delete(this.dashboard.uuid);
			App.navigator.pop();
		}
	}
}
