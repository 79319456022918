import {Component, Input, ViewEncapsulation} from '@angular/core';
import {DashboardBarChartWidgetComponent} from '../bar-chart-widget/dashboard-bar-chart.component';
import {DashboardLineChartWidgetComponent} from '../line-chart-widget/dashboard-line-chart-widget.component';
import {DashboardDoughnutChartWidgetComponent} from '../doughnut-chart-widget/dashboard-doughnut-chart-widget.component';
import {DashboardMapWidgetComponent} from '../map-widget/dashboard-map-widget.component';
import {WidgetViewTypes} from '../../../../../models/dashboards/widget-view-types';
import {WidgetComponent} from '../widget-component';

@Component({
	selector: 'uno-generic-widget',
	templateUrl: 'generic-widget.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		DashboardBarChartWidgetComponent,
		DashboardLineChartWidgetComponent,
		DashboardDoughnutChartWidgetComponent,
		DashboardMapWidgetComponent
	]
})
export class UnoGenericWidgetComponent implements WidgetComponent {
	public widgetViewTypes = WidgetViewTypes;

	/**
	 * The configuration data of the widget.
	 */
	@Input()
	public config: any;

	/**
	 * Data to show on the widget.
	 */
	@Input()
	public data: any;
}
