import {WidgetDemo} from 'src/app/modules/dashboard-new/data/widgets/demo-widget/demo-widget';
import {WidgetAssetDistribution} from 'src/app/modules/dashboard-new/data/widgets/asset-distribution-widget/asset-distribution-widget';
import {WidgetAtexInspectionResult} from 'src/app/modules/dashboard-new/data/widgets/atex-inspection-result/atex-inspection-result-widget';
import {WidgetTypes} from '../../../models/dashboards/widget-types';
import {Widget} from './widget-interface';
import {WidgetRepairsCriticality} from './widgets/repairs-criticality-widget/repairs-criticality-widget';
import {WidgetAtexActionPlanProgress} from './widgets/atex-action-plan-progress/atex-action-plan-progress-widget';
import {WidgetRepairProgress} from './widgets/repair-progress/repair-progress';

/**
 * Map of the widget type and its corresponding label as stored in locale and also its corresponding widget.
 */
export const WidgetsMap: Map<number, {label: string, widget: Widget}> = new Map([
	[WidgetTypes.DEMO_WIDGET, {label: 'demoWidget', widget: new WidgetDemo()}],
	[WidgetTypes.ASSET_DISTRIBUTION_BY_TYPE_SUBTYPE, {label: 'widgetDistributionByType', widget: new WidgetAssetDistribution()}],
	[WidgetTypes.ASSET_MAP_ZONE, null],
	[WidgetTypes.ATEX_INSPECTION_DISTRIBUTION_BY_STATE, null],
	[WidgetTypes.ATEX_INSPECTION_RESULTS, {label: 'atexInspectionResults', widget: new WidgetAtexInspectionResult()}],
	[WidgetTypes.ATEX_INSPECTION_CARRIED_OUT, null],
	[WidgetTypes.ATEX_INSPECTION_REVISED, null],
	[WidgetTypes.ATEX_INSPECTION_UPDATED, null],
	[WidgetTypes.INSPECTION_COUNT_BY_STATE, null],
	[WidgetTypes.INSPECTION_ANSWER_DISTRIBUTION, null],
	[WidgetTypes.ATEX_ACTION_PLAN_COUNT_BY_STATE, null],
	[WidgetTypes.ATEX_ACTION_PLAN_PROGRESS, {label: 'atexActionPlanProgress', widget: new WidgetAtexActionPlanProgress()}],
	[WidgetTypes.ATEX_ACTION_PLAN_REJECTED, null],
	[WidgetTypes.ATEX_ACTION_PLAN_EXECUTED, null],
	[WidgetTypes.ATEX_ACTION_PLAN_BLOCKED, null],
	[WidgetTypes.GAP_SOLVED, null],
	[WidgetTypes.REPAIR_BY_STATUS, {label: 'repairsProgressDashTitle', widget: new WidgetRepairProgress()}],
	[WidgetTypes.REPAIR_BY_CRITICALITY, {label: 'criticalityGraphTitle', widget: new WidgetRepairsCriticality()}],
	[WidgetTypes.REPAIR_INSPECTIONS_BY_STATE, null]
]);
