import {Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {App} from 'src/app/app';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule, IonSelect} from '@ionic/angular';
import {DOMUtils} from 'src/app/utils/dom-utils';
import {AsyncUtils} from 'src/app/utils/async-utils';
import {NgClass} from '@angular/common';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {ResizeDetector} from 'src/app/utils/resize-detector';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';
import {UnoFormField, OptionsDisplayMode} from '../../uno-forms/uno-form/uno-form-field';
import {UnoFormUtils} from '../../uno-forms/uno-form/uno-form-utils';


export type OptionSelectorOption = {
	// The text to present for the option
	label: string,

	// The value of the option
	value: any
};

/**
 * Form input options box.
 *
 * Used to select a single option.
 */
@Component({
	selector: 'uno-options',
	templateUrl: './uno-options.component.html',
	styleUrls: ['./uno-options.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoOptionsComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [CdkConnectedOverlay, CdkOverlayOrigin, IonicModule, FormsModule, TranslateModule, UnoIconComponent, NgClass]
})
export class UnoOptionsComponent implements ControlValueAccessor, OnChanges {
	public optionsDisplayMode = OptionsDisplayMode;

	public app: any = App;

	public formUtils: any = UnoFormUtils;

	@ViewChild('optionsInput', {static: false})
	public optionsInput: ElementRef = null;

	@ViewChild('ionSelect', {static: false})
	public ionSelect: IonSelect = null;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * If set, sorts all the items alphabetically.
	 */
	@Input()
	public sort: boolean = false;

	/**
	 * If set, the options will be automatically translated
	 */
	@Input()
	public translate: boolean = true;

	/**
	 * Options to display for selection.
	 */
	@Input()
	public options: OptionSelectorOption[] = [];

	/**
	 * The display mode for the options component.
	 */
	@Input()
	public displayMode: number = OptionsDisplayMode.POPOVER;

	/**
	 * The row form field to fetch the options for.
	 */
	@Input()
	public row: UnoFormField = null;

	/**
	 * The the object to get the options for.
	 */
	@Input()
	public object: any = null;

	/**
	 * The selected option value.
	 */
	public value: any = null;

	/**
	 * Flag to indicate if the options selection is open.
	 */
	public expanded: boolean = false;

	/**
	 * Flag to indicate if the options selection is closing.
	 */
	public closing: boolean = false;

	/**
	 * Width of dropdown
	 */
	public width: number = 0;

	/**
	 * Height of dropdown
	 */
	public height: number = 180;

	public resize: ResizeDetector | null = null;

	/**
	 * Sets size of dropdown
	 */
	public ngOnInit(): void {
		setTimeout(() => {
			if (this.optionsInput) {
				this.width = this.optionsInput.nativeElement.clientWidth;

				this.resize = new ResizeDetector(this.optionsInput.nativeElement, () => {
					this.width = this.optionsInput.nativeElement.clientWidth;
				});
			}
		}, 2000);
		if (this.options.length < 4) {
			this.height = 32;
			this.options.forEach(() => {
				this.height += 36;
			});
		}
	}

	/**
	 * Method used to show and hide the options when clicking the selector.
	 */
	public async toggle(): Promise<void> {
		if (!this.disabled) {
			if (this.closing) {
				setTimeout(() => {
					this.expanded = false;
					this.closing = false;
				}, 300);
			} else {
				this.expanded = true;
				await AsyncUtils.await(100);
				const element: HTMLElement = document.querySelector('[aria-label="' + this.getLabel(this.value) + '"]') as HTMLElement;
				await DOMUtils.waitUntilRendered(element);
				if (this.options.length > 4) {
					this.scroll(element);
				}
			}
		}
	}

	/**
	 * Toggles modal for ionic selects
	 */
	public toggleModal(): void {
		if (!this.disabled) {
			this.expanded = true;
			this.ionSelect.open();
		} 		
	}

	public ngOnDestroy(): void {
		if (this.resize) {
			this.resize.destroy();
		}
	}

	/**
	 * Get label from the value selected.
	 * 
	 * @param value - The value to be used to search for the right label
	 */
	public getLabel(value: any): string {
		const opt = this.options.filter((val: any) => {
			return val.value === value;
		});

		return opt.length === 0 ? '' : opt[0].label;
	}

	/**
	 * Scrolls the dropdown to the currently selected value
	 * 
	 * @param element - The element that has its value currently selected
	 */
	public scroll(element: HTMLElement): void {
		if (element) {
			element.scrollIntoView();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.sort && this.sort) {
			this.options.sort((a: any, b: any) => { return a.label > b.label ? 1 : -1;});
		}
	}

	public onChange: (value: any)=> void = function() {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	/**
	 * Update the value of the input.
	 * 
	 * @param value - value to update the input with.
	 */
	public updateValue(value: any): void {
		this.value = value;
		this.expanded = false;
		this.onChange(this.value);
	}

	public writeValue(value: any): void {
		this.value = value;
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
}
