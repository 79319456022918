<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.expanded">
	<div class="uno-options-list-unscrollable" (click)="this.closing = true; this.toggle()"></div>
</ng-template>

<div class="uno-options-list-container" [style.width.px]="width" [ngClass]="{'disabled': this.disabled}">
	<div class="uno-options-list-input" (click)="this.toggle()" cdkOverlayOrigin #originOverlay="cdkOverlayOrigin" [ngClass]="{'selected': this.expanded && !this.fullArrow, 'selected-fullarrow': this.expanded && this.fullArrow, 'uno-options-list-input-border-fullarrow': !this.expanded && this.fullArrow}">
		<span class="uno-options-list-label" [style.width.px]="width-50"> {{this.getLabel(this.value) | translate}}</span>
		<uno-icon class="uno-options-list-icon" [ngClass]="{'rotate': !this.expanded, 'disabled': this.disabled}" color="{{!this.expanded ? 'var(--gray-5)' : 'var(--primary-70)' }}" [width]="32" [height]="32" [src]="this.fullArrow ? './assets/icons/uno/up-fullarrow.svg': './assets/components/options/up-specialarrow-icon.svg'"></uno-icon>
	</div>
	<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.expanded">
		<div class="uno-options-list-dropdown" [style.width.px]="width" [ngClass]="{'close': this.closing}">
			<!-- Options -->
			<div class="uno-options-list-dropdown-option-container" [style.width.px]="width">
				@for (option of this.options; track option) {
					<span class="uno-options-list-label uno-options-list-dropdown-option" [style.width.px]="width-24" [attr.aria-label]="option.label" (click)="this.closing = true; this.writeValue(option.value)">{{option.label | translate}} </span>
				}
			</div>
			<div class="uno-options-list-scrollbar-padding"></div>
		</div>
	</ng-template>
</div>
