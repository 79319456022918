import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {CSSUtils} from 'src/app/utils/css-utils';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {WidgetViewTypes} from 'src/app/models/dashboards/widget-view-types';
import {cloneDeep} from 'lodash-es';
import {RepairService} from 'src/app/modules/repairs/repair-work/services/repair.service';
import {RepairCriticalityLevel, RepairCriticalityLevelLabel} from 'src/app/models/repairs/repairs/repair-criticality';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {ChartData} from '../../../components/widget/widget.component';
import {WidgetBaseLayout} from '../../../screens/widget-layout';
import {UnoGenericWidgetComponent} from '../../../components/widgets/generic-widget/generic-widget.component';
import {Widget} from '../../widget-interface';

export class WidgetRepairsCriticality implements Widget {
	public type = WidgetTypes.REPAIR_BY_CRITICALITY;

	public config = function(): void {
		this.chartType = WidgetViewTypes.DOUGHNUT_CHART;
		this.repairCriticalityLevels = [];
	};

	public component = UnoGenericWidgetComponent;

	public permissions = [UserPermissions.REPAIR];

	public async getData(widget: DashboardWidget): Promise<ChartData> {
		// Count number of inspections of each type
		const request = await RepairService.countCriticality(true, false);

		let chartLabels: string[] = [];

		let datasetData: number[] = [];

		if (!widget.data.repairCriticalityLevels || widget.data.repairCriticalityLevels.length === 0 || widget.data.repairCriticalityLevels.length === 7) {
			chartLabels = [
				Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.NONE)),
				Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.VERY_LOW)),
				Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.LOW)),
				Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.NORMAL)),
				Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.HIGH)),
				Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.VERY_HIGH)),
				Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.EMERGENCY))
			];

			datasetData = [
				request.none,
				request.veryLow,
				request.low,
				request.normal,
				request.high,
				request.veryHigh,
				request.emergency
			];
		} else {
			for (const criticality of widget.data.repairCriticalityLevels) {
				switch (criticality) {
					case RepairCriticalityLevel.NONE:
						chartLabels.push(Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.NONE)));
						datasetData.push(request.none);
						break;

					case RepairCriticalityLevel.VERY_LOW:
						chartLabels.push(Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.VERY_LOW)));
						datasetData.push(request.veryLow);
						break;

					case RepairCriticalityLevel.LOW:
						chartLabels.push(Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.LOW)));
						datasetData.push(request.low);
						break;

					case RepairCriticalityLevel.NORMAL:
						chartLabels.push(Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.NORMAL)));
						datasetData.push(request.normal);
						break;

					case RepairCriticalityLevel.HIGH:
						chartLabels.push(Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.HIGH)));
						datasetData.push(request.high);
						break;

					case RepairCriticalityLevel.VERY_HIGH:
						chartLabels.push(Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.VERY_HIGH)));
						datasetData.push(request.veryHigh);
						break;

					case RepairCriticalityLevel.EMERGENCY:
						chartLabels.push(Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.EMERGENCY)));
						datasetData.push(request.emergency);
						break;

					default:
						throw new Error('Invalid Repair Criticality');
				}
			}
		}

		const chartData = {
			labels: chartLabels,
			datasets: [
				{
					label: '',
					data: datasetData,
					backgroundColor: [
						CSSUtils.getVariable('--primary-70'),
						CSSUtils.getVariable('--success-normal'),
						CSSUtils.getVariable('--warning-normal'),
						CSSUtils.getVariable('--error-normal'),
						CSSUtils.getVariable('--special-violet-1'),
						CSSUtils.getVariable('--special-blue-2'),
						CSSUtils.getVariable('--special-yellow-2')
					],
					borderColor: [
						CSSUtils.getVariable('--primary-70'),
						CSSUtils.getVariable('--success-normal'),
						CSSUtils.getVariable('--warning-normal'),
						CSSUtils.getVariable('--error-normal'),
						CSSUtils.getVariable('--special-violet-1'),
						CSSUtils.getVariable('--special-blue-2'),
						CSSUtils.getVariable('--special-yellow-2')
					],
					cutout: '70%'
				}
			]
		};

		return chartData;
	};

	public async openEditModal(widget: DashboardWidget): Promise<DashboardWidget> {
		const editLayout = cloneDeep(WidgetBaseLayout);
		editLayout.push({
			required: false,
			label: 'status',
			attribute: 'data.repairCriticalityLevels',
			multiple: true,
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
			options: [
				{label: Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.NONE)), value: RepairCriticalityLevel.NONE}, 
				{label: Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.VERY_LOW)), value: RepairCriticalityLevel.VERY_LOW}, 
				{label: Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.LOW)), value: RepairCriticalityLevel.LOW}, 
				{label: Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.NORMAL)), value: RepairCriticalityLevel.NORMAL}, 
				{label: Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.HIGH)), value: RepairCriticalityLevel.HIGH}, 
				{label: Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.VERY_HIGH)), value: RepairCriticalityLevel.VERY_HIGH}, 
				{label: Locale.get(RepairCriticalityLevelLabel.get(RepairCriticalityLevel.EMERGENCY)), value: RepairCriticalityLevel.EMERGENCY}
			]
		});

		await Modal.form(Locale.get('edit'), widget, editLayout);

		return widget;
	}
}
