/**
 * List of possible widget types.
 */
export enum WidgetTypes {
	// Dummy Widget to be used on the demo page with random values
	DEMO_WIDGET= 1,
	// Quantity of assets in each state, can be filtered by type and subtype
	ASSET_DISTRIBUTION_BY_TYPE_SUBTYPE= 1001,
	// Display map with zoom and coordinates
	ASSET_MAP_ZONE= 1002,
	// Quantity of atex inspection for each state
	ATEX_INSPECTION_DISTRIBUTION_BY_STATE= 2001,
	// Results of the atex inspections(approved, finished, etc...)
	ATEX_INSPECTION_RESULTS= 2002,
	// Atex inspections carried out by day
	ATEX_INSPECTION_CARRIED_OUT= 2003,
	// Atex inspections revised by the backoffice team by day
	ATEX_INSPECTION_REVISED= 2004,
	// Atex inspections updated daily
	ATEX_INSPECTION_UPDATED= 2005,
	// Count of inspections by state from a project
	INSPECTION_COUNT_BY_STATE= 3001,
	// Distribution of answers from an inspection per project
	INSPECTION_ANSWER_DISTRIBUTION= 3002,
	// Count of atex action plans by state
	ATEX_ACTION_PLAN_COUNT_BY_STATE= 4001,
	// Count of rejected vs accepted atex action plans
	ATEX_ACTION_PLAN_REJECTED= 4002,
	// Count of executed vs to do atex action plans
	ATEX_ACTION_PLAN_EXECUTED= 4003,
	// Count of blocked vs approved atex action plans
	ATEX_ACTION_PLAN_BLOCKED= 4004,
	// Count of all atex action plan states
	ATEX_ACTION_PLAN_PROGRESS= 4005,
	// Count of solved vs unsolved gaps
	GAP_SOLVED= 5001,
	// Count of repairs by inspection state
	REPAIR_BY_STATUS= 6001,
	// Count of repairs by criticality
	REPAIR_BY_CRITICALITY= 6002,
	// Count of repair inspections by state
	REPAIR_INSPECTIONS_BY_STATE= 6003
};
