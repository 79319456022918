import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	OnChanges,
	OnInit,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
	input
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {NgClass, NgStyle} from '@angular/common';
import AirDatepicker, {AirDatepickerButtonPresets, AirDatepickerLocale, AirDatepickerOptions} from 'air-datepicker';
import en from 'air-datepicker/locale/en';
import es from 'air-datepicker/locale/es';
import pt from 'air-datepicker/locale/pt';
import {DateRange} from 'src/app/models/date-range';
import {App} from '../../../app';
import {Locale} from '../../../locale/locale';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';
import {AnimationTimer} from '../../../utils/timer/animation-timer';

/**
 * Type of date time mode can be 'date-time', 'date' or 'time' only.
 *
 * 'date-time' shows both date and time (day, month, year, hours, ...) selectors.
 *
 * 'time' displays only time (hours, minutes, ...) selectors.
 */
export type DateTimeMode = 'date-time' | 'date' | 'time' | 'month-year';

@Component({
	selector: 'uno-date-time',
	templateUrl: './uno-date-time.component.html',
	styleUrls: ['uno-date-time.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoDateTimeComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [NgStyle, NgClass, IonicModule, TranslateModule, UnoIconComponent]
})
export class UnoDateTimeComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
	@ViewChild('input', {static: true})
	public inputElem: ElementRef = null;

	@ViewChild('container', {static: true})
	public containerElem: ElementRef = null;

	public app = App;

	public locale = Locale;

	/**
	 * Maximum date to be used by default.
	 */
	public static maxDefault: Date = new Date(2100, 0, 0);

	/**
	 * Minimum date to be used by default.
	 */
	public static minDefault: Date = new Date(1900, 0, 0);

	/**
	 * Border to be displayed around the input.
	 */
	public readonly border = input<boolean>(true);

	/**
	 * Time selection mode.
	 */
	public readonly mode = input<DateTimeMode>('date-time');

	/**
	 * Allow the input to be disabled.
	 */
	public readonly disabled = input<boolean>(false);

	/**
	 * Placeholder text to be displayed when there is no date selected.
	 */
	public readonly placeholder = input<string>('');

	/**
	 * The maximum date time the user can choose from.
	 */
	public readonly max = input<Date>(null);

	/**
	 * The minimum date time the user can choose from.
	 */
	public readonly min = input<Date>(null);
	
	/**
	 * Indicates if the datepicker will be used to select a date range. 
	 */
	public readonly isRange = input<boolean>(false);

	/**
	 * Dates selected (initial value).
	 */
	public readonly selectedDates = input<Date[]>(null);

	/**
	 * Event to emit change to the date range.
	 */
	@Output()
	public onUpdate = new EventEmitter<Date | DateRange>();
	
	/**
	 * Value currently selected.
	 */
	public value: Date | DateRange = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: Date | DateRange)=> void = function() {};

	/**
	 * Datepicker element
	 */
	public datePicker: AirDatepicker = null;

	/**
	 * If the element is focused
	 */
	public focused: boolean = false;

	/**
	 * List of possible locale translations for the datepicker
	 */
	public locales: {en: AirDatepickerLocale, es: AirDatepickerLocale, pt: AirDatepickerLocale} = {en: en, es: es, pt: pt};

	/**
	 * Timer used to update position of the datepicker.
	 */
	public scrollTimer: AnimationTimer = null;

	/**
	 * Backdrop element.
	 */
	public backdrop: HTMLElement = null;

	public ngOnInit(): void {
		this.scrollTimer = new AnimationTimer((time: number) => {
			if (this.datePicker.visible) {
				this.updatePickerPosition(this.datePicker.$datepicker, this.inputElem.nativeElement);
			}
		});

		this.backdrop = this.createBackdrop();

		this.datePicker = this.createDatePicker();
	}

	public ngOnDestroy(): void {
		if (this.scrollTimer.running) {
			this.scrollTimer.stop();
		}

		if (document.body.contains(this.backdrop)) {
			document.body.removeChild(this.backdrop);
		}
	}
	
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.disabled) {
			this.inputElem.nativeElement.style.pointerEvents = this.disabled() ? 'none' : null;
		}
	}

	/**
	 * Create a backdrop element to be displayed behind the datepicker.
	 * 
	 * @returns - Backdrop element.
	 */
	public createBackdrop(): HTMLElement {
		const backdrop = document.createElement('div');
		backdrop.style.position = 'fixed';
		backdrop.style.top = '0';
		backdrop.style.left = '0';
		backdrop.style.width = '100%';
		backdrop.style.height = '100%';
		backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
		backdrop.style.zIndex = '99999';
		backdrop.onscroll = (event) => {
			event.stopImmediatePropagation();
		};
		backdrop.onclick = (event) => {
			this.datePicker.hide();
			event.stopImmediatePropagation();
		};
		return backdrop;
	}

	/**
	 * Update picker position relative to the input element.
	 * 
	 * The picker will be displayed below the input element if possible.
	 * 
	 * @param datepicker - Datepicker element
	 * @param inputElement - Input element
	 */
	public updatePickerPosition(datepicker: HTMLElement, inputElement: HTMLElement): void {
		const bounds = inputElement.getBoundingClientRect();
		datepicker.style.top = bounds.y + bounds.height + 10 + 'px';
		datepicker.style.left = bounds.x + 'px';
		datepicker.style.zIndex = String(1e5);

		// If the datepicker is going to be displayed outside the window, move it up.
		if (bounds.y + bounds.height + 10 + datepicker.clientHeight > window.innerHeight) {
			datepicker.style.top = bounds.y - datepicker.clientHeight - 10 + 'px';
		}
	}


	/**
	 * Create and configure datepicker instance.
	 * 
	 * @returns - Datepicker instance.
	 */
	public createDatePicker(): AirDatepicker {
		const locale = this.locales[this.locale.code];
		
		const todayButton = {
			content: Locale.get('today'),
			onClick: (dp: AirDatepicker) => {
				const date = new Date();

				if (!this.isRange()) {
					dp.selectDate(date);
				}
				
				dp.setViewDate(date);
			}
		};

		const dates = this.selectedDates();

		if (dates?.length === 2 && dates?.filter((d) => { return d !== null && d !== undefined; }).length !== 2) {
			throw new Error('Invalid pair of dates received. Both dates must be valid.');
		}

		if (dates?.length > 2) {
			throw new Error('Maximum 2 dates allowed for selection');
		}

		const options: AirDatepickerOptions = {
			container: document.body,
			range: this.isRange(),
			multipleDatesSeparator: ' - ',
			locale: locale,
			dateFormat: 'dd/MM/yyyy',
			navTitles: {days: 'MMMM yyyy'},
			selectedDates: dates?.filter((d) => { return d !== null && d !== undefined; }).length > 0 ? dates : false,
			minDate: this.minDate,
			maxDate: this.maxDate,
			buttons: [todayButton, 'clear'] as AirDatepickerButtonPresets[],
			isMobile: false,
			autoClose: true,
			onShow: () => {
				this.focused = true;
				this.scrollTimer.start();
				document.body.appendChild(this.backdrop);	
			},
			onHide: () => {
				this.focused = false;
				this.scrollTimer.stop();
				document.body.removeChild(this.backdrop);
			},
			onSelect: ({date}) => {
				this.updateValue(date);
			},
			position: ({$datepicker, $target, $pointer}) => {
				this.updatePickerPosition($datepicker, $target);
			}
		};

		return new AirDatepicker(this.inputElem.nativeElement, options);
	}

	/**
	 * Get max date range in format YYYY-MM-DD
	 */
	public get maxDate(): string {
		const max = this.max();
  		if (!max) {
			return UnoDateTimeComponent.maxDefault.toISOString().substring(0, 10);
		}

		return new Date(max).toISOString().substring(0, 10);
	}

	/**
	 * Get min date range in format YYYY-MM-DD
	 */
	public get minDate(): string {
		const min = this.min();
  		if (!min) {
			return UnoDateTimeComponent.minDefault.toISOString().substring(0, 10);
		}

		return new Date(min).toISOString().substring(0, 10);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public updateValue(value: string | Date | Date[]): void {
		const isRange = this.isRange();

		// @ts-ignore
		if (!value || value?.length === 0) {
			this.value = null;
			this.onChange(null);
			this.onUpdate.emit(null);
		}

  		if (isRange && value[0] && value[1]) {
			this.value = new DateRange(value[0], value[1]);
			this.onUpdate.emit(this.value);
			this.onChange(this.value);
		} else if (!isRange) {
			if (typeof value === 'string') {
				value = new Date(value);
			}
	
			this.value = value as Date;
			this.onUpdate.emit(this.value);
			this.onChange(this.value);
		}		
	}
	
	public writeValue(value: Date | DateRange): void {
		this.value = value;
	}

	public registerOnTouched(fn: any): void {}
}
