<!-- Transparent background to prevent scrolling and close the dropdowns on click -->
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="periodicityOriginOverlay" [cdkConnectedOverlayOpen]="this.periodicityExpanded || this.repeatExpanded">
	<div class="uno-periodicity-input-unscrollable" (click)="this.periodicityExpanded = false; this.repeatExpanded = false">
	</div>
</ng-template>

<!-- The container that holds the inputs and dropdowns -->
<div class="uno-periodicity-selector-container">
	<!-- Container that holds both of the inputs -->
	<div class="uno-periodicity-selector-input-container">
		<!-- The repeat input -->
		<div class="uno-periodicity-selector-option-container uno-periodicity-selector-repeat" [ngClass]="{'disabled':this.disabled, 'active':this.repeatExpanded}" cdkOverlayOrigin #repeatOriginOverlay="cdkOverlayOrigin">
			<input type="number" class="uno-periodicity-selector-input" (ngModelChange)="setRepeat($event)" [ngClass]="{'disabled':this.disabled}" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.repeatNumber" periodicity="number" min="1" oninput="this.value = this.value > this.min ? this.value : 1" [disabled]="disabled" />
			<uno-icon class="uno-periodicity-selector-arrow" [ngClass]="{'rotate': !this.repeatExpanded, 'disabled': this.disabled}" color="{{!this.disabled ? (!this.repeatExpanded ? 'var(--gray-5)' : 'var(--primary-70)') : 'var(--gray-9)' }}" [width]="32" [height]="32" src="./assets/components/options/up-specialarrow-icon.svg" (click)="this.repeatExpanded = !this.disabled ? true : false"></uno-icon>
		</div>
		<!-- The periodicity input -->
		<div class="uno-periodicity-selector-option-container" [ngClass]="{'disabled':this.disabled, 'active':this.periodicityExpanded}" (click)="this.periodicityExpanded = !this.disabled ? true : false" cdkOverlayOrigin #periodicityOriginOverlay="cdkOverlayOrigin">
			<span class="uno-periodicity-selector-option-label uno-periodicity-input-placeholder" [ngClass]="{'disabled':this.disabled}" (ngModelChange)="setPeriodicity($event)">{{this.periodicity | translate}}</span>
			<uno-icon class="uno-periodicity-selector-arrow" [ngClass]="{'rotate': !this.periodicityExpanded, 'disabled': this.disabled}" color="{{!this.disabled ? (!this.periodicityExpanded ? 'var(--gray-5)' : 'var(--primary-70)') : 'var(--gray-9)' }}" [width]="32" [height]="32" src="./assets/components/options/up-specialarrow-icon.svg"></uno-icon>
		</div>
	</div>
	<!-- The dropdown that holds the repeat numbers -->
	<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="repeatOriginOverlay" [cdkConnectedOverlayOpen]="this.repeatExpanded">
		<div class="uno-periodicity-selector-repeat-dropdown uno-periodicity-input-dropdown">
			<div class="uno-periodicity-selector-option uno-periodicity-input-dropdown">
				@for (number of this.numbers; track number) {
					<span class="uno-periodicity-selector-option-label" (click)="setRepeat(number)">{{number}}</span>
				}
			</div>
			<div class="uno-periodicity-input-scrollbar-padding"></div>
		</div>
	</ng-template>
	<!-- The dropdown that holds the periodicity -->
	<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="periodicityOriginOverlay" [cdkConnectedOverlayOpen]="this.periodicityExpanded">
		<div class="uno-periodicity-selector-dropdown">
			<div class="uno-periodicity-selector-option uno-periodicity-input-periodicity uno-periodicity-input-dropdown">
				@for (option of this.options; track option) {
					<span class="uno-periodicity-selector-option-label" (click)="setPeriodicity(option.value)">{{option.label | translate}}</span>
				}
			</div>
			<div class="uno-periodicity-input-scrollbar-padding"></div>
		</div>
	</ng-template>
</div>
