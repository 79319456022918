import {WidgetViewTypes} from 'src/app/models/dashboards/widget-view-types';
import {WidgetSize} from 'src/app/models/dashboards/widget-size';
import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';
import {WidgetTypes} from '../../../models/dashboards/widget-types';

/**
 * Array containing the chart available chart options to use on the widget layout.
 */
export const WidgetChartOptions: {label: string, value: WidgetViewTypes}[] = [
	{label: 'barChart', value: WidgetViewTypes.BAR_CHART},
	{label: 'pieChart', value: WidgetViewTypes.DOUGHNUT_CHART},
	{label: 'lineChart', value: WidgetViewTypes.LINE_CHART},
	{label: 'map', value: WidgetViewTypes.MAP_CHART}
];

/**
 * The base widget layout.
 */
export const WidgetBaseLayout: UnoFormField[] = [
	{
		required: true,
		label: 'name',
		attribute: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		label: 'size',
		attribute: 'size',
		type: UnoFormFieldTypes.OPTIONS,
		options: [
			{label: 'small', value: WidgetSize.SMALL},
			{label: 'medium', value: WidgetSize.MEDIUM},
			{label: 'large', value: WidgetSize.LARGE}
		]
	},
	{
		required: false,
		label: 'chart',
		attribute: 'data.chartType',
		type: UnoFormFieldTypes.OPTIONS,
		isActive: function(object, row) {
			return object.data.chartType !== undefined;
		},
		fetchOptions: function(object, row) {
			const options = [];

			if (object.type === WidgetTypes.DEMO_WIDGET) {
				options.push(...[
					{label: 'barChart', value: WidgetViewTypes.BAR_CHART},
					{label: 'pieChart', value: WidgetViewTypes.DOUGHNUT_CHART},
					{label: 'lineChart', value: WidgetViewTypes.LINE_CHART}
				]);
			} else if (object.type === WidgetTypes.ASSET_DISTRIBUTION_BY_TYPE_SUBTYPE) {
				options.push(...[
					{label: 'barChart', value: WidgetViewTypes.BAR_CHART},
					{label: 'pieChart', value: WidgetViewTypes.DOUGHNUT_CHART}
				]);
			}

			row.options = options;
		}	
	}
];
