<!-- Header -->
<div class="menu-mobile-header-container">
	<div class="menu-mobile-text-container">
		<div style="display:flex; flex-direction: row; align-items: center; gap: 5px;">
			<img class="menu-desktop-arrow-back" src="../assets/components/menu/arrow-left.svg" (click)="app.navigator.pop();" [ngStyle]="{'filter': session.settings.theme=='dark' ? 'invert()' : 'unset'}" />
			@for (path of app.navigator.breadcrumbs(); track path) {
				<label class="menu-mobile-breadcrumbs" (click)="app.navigator.rollback(path);">{{path.title | translate}} /</label>
			}
		</div>

		<div class="menu-mobile-title" style="text-overflow: ellipsis;">{{app.navigator.title() | translate}}</div>
	</div>

	<div style="margin-right: 20px;">
		<user-avatar></user-avatar>
	</div>

</div>

<!-- Content -->
<div style="position: absolute; z-index: 0; top: 85px; left: 0px; width: 100%; height: calc(100% - 155px)">
	<router-outlet></router-outlet>
</div>

<!-- Navigation bar -->
<div class="menu-mobile-nav" [ngStyle]="{'background-color': session.settings.theme=='dark' ? 'var(--gray-13)' : 'unset'}" style="position: absolute; bottom: 0px; left: 0px; width: 100%; height: 70px; transform: rotateX(180deg); overflow-x: auto; overflow-y: hidden;">
	<div style="transform: rotateX(180deg); z-index: 2; color: rgb(102, 102, 102); display: flex;">
		@for (page of this.pages; track page) {
			@if (page.available && (page.permissions | hasPermissions) && (page.module | moduleCheck)) {
				<div (click)="app.navigator.navigate(page.route, page.data);" style="height: 66px; display: flex; align-items: center; text-align: center; flex-direction: column;">
					<div [ngStyle]="{'background-color': menuOption.highlighted(page) ? 'var(--primary-70)' : 'unset'}" style="height: 6px; width: 100%; border-radius: 3px;"></div>
					<uno-icon color="{{menuOption.highlighted(page) ? session.settings.theme=='dark' ? 'var(--brand-contrast)' : 'var(--primary-70)' : 'var(--gray-7)'}}" style="width: 26px; height: 26px;" [width]="26" [height]="26" src="{{page.icon}}"></uno-icon>
					<div class="menu-mobile-icon-label" [ngStyle]="{color: menuOption.highlighted(page) ? session.settings.theme=='dark' ? 'var(--brand-contrast)' : 'var(--primary-70)' : 'var(--gray-7)'}">{{page.label | translate }}</div>
				</div>
			}
		}
	</div>
</div>
