/**
 * Color styles that can be applied to the icon element.
 */
export const UnoColor = {
	CONTRAST: 'contrast',
	GRAY: 'gray',
	LIGHT: 'light',
	DARK: 'dark',
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	ERROR: 'error',
	WARNING: 'warning',
	SUCCESS: 'success',
	INFORMATION: 'information'
};

/**
 * List of fill styles to apply to the SVG element based on the icon color selected.
 */
export const UnoColorMap = new Map<string, string>([
	[UnoColor.CONTRAST, 'var(--gray-0)'],
	[UnoColor.GRAY, 'var(--gray-8)'],
	[UnoColor.LIGHT, 'var(--light)'],
	[UnoColor.DARK, 'var(--dark)'],
	[UnoColor.PRIMARY, 'var(--primary-70)'],
	[UnoColor.ERROR, 'var(--error-normal)'],
	[UnoColor.WARNING, 'var(--warning-normal)'],
	[UnoColor.SUCCESS, 'var(--success-normal)'],
	[UnoColor.INFORMATION, 'var(--information-normal)']
]);
