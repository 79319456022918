<div class="uno-widget-container">
	<div class="uno-widget-text-container">
		<div class="uno-widget-title-container">
			<h3 class="uno-h7-bold uno-widget-title" title="{{this.widget.name}}">{{this.widget.name}}</h3>
			@if (this.widget.data.demo || this.editable) {
				<div class="uno-widget-icons-container">
					<uno-icon [src]="'assets/icons/assets/edit-icon.svg'" [color]="'var(--primary-70)'" [width]="24" [height]="24" (click)="this.openEditModal()" title="{{'edit' | translate}}"></uno-icon>
					<uno-icon [src]="'assets/icons/uno/bin.svg'" [color]="'var(--primary-70)'" [width]="24" [height]="24" (click)="this.deleteWidget()" title="{{'delete' | translate}}"></uno-icon>
				</div>
			}

		</div>
		<h3 class="uno-p2 uno-widget-subtitle" title="{{this.widget.description}}">{{this.widget.description}}</h3>
	</div>
	<div class="uno-widget-data-container">
		<ng-container *ngComponentOutlet="this.widgetInterface.component;inputs: {data: data, config: this.widget.data};"></ng-container>
	</div>
</div>
