import {Component, OnInit, ViewChild} from '@angular/core';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {cloneDeep} from 'lodash-es';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoFormComponent} from '../../../../../../components/uno-forms/uno-form/uno-form.component';
import {AssetTypeService} from '../../../../services/asset-type.service';
import {AssetFormBlockService} from '../../../../services/asset-form-block.service';
import {ArrayUtils} from '../../../../../../utils/array-utils';
import {UnoFormField} from '../../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormUtils} from '../../../../../../components/uno-forms/uno-form/uno-form-utils';
import {App} from '../../../../../../app';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Session} from '../../../../../../session';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {generateUUID, UUID} from '../../../../../../models/uuid';
import {AssetTypeLayout} from '../../asset-types-layout';
import {APAssetType} from '../../../../../../models/asset-portfolio/asset-type';
import {APAssetFormTab} from '../../../../../../models/asset-portfolio/asset-form-tab';
import {APAssetFormTabCard} from '../../../../../../models/asset-portfolio/asset-form-tab-card';
import {APAssetFormTabLayout, AssetFormBlockFieldLayout, AssetFormTabCardLayout} from '../../asset-template-layout';
import {APAssetFormBlock} from '../../../../../../models/asset-portfolio/asset-form-block';
import {APAssetFormBlockField} from '../../../../../../models/asset-portfolio/asset-form-block-field';
import {FormSortUtils} from '../../../../../../utils/form-sort-utils';
import {FormatDatePipe} from '../../../../../../pipes/format-date.pipe';
import {UnoTitleComponent} from '../../../../../../components/uno/uno-title/uno-title.component';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoTabSectionComponent} from '../../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../../components/uno/uno-tab/uno-tab.component';
import {PermissionsPipe} from '../../../../../../pipes/permissions.pipe';

/**
 * Page used to edit and create asset types. Should receive the UUID of the asset type to edit or a createMode value set to true.
 */
@Component({
	selector: 'asset-type-edit-page',
	templateUrl: './asset-type-edit.page.html',
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoFormModule, UnoButtonComponent, UnoTitleComponent, IonicModule, NgStyle, TranslateModule, FormatDatePipe, PermissionsPipe]
})
export class AssetTypeEditPage extends ScreenComponent implements OnInit {
	@ViewChild (UnoTabComponent)
	public unoTab: any;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public app: any = App;

	public assetTypeLayout: UnoFormField[] = AssetTypeLayout;
	
	public formTabLayout: UnoFormField[] = APAssetFormTabLayout;
	
	/**
	 * This is the base layout of a form tab card, and it must be accesable/editable on this component to implement posterior onChange callback methods on some fields to trigger actions on this component level.
	 */
	public formTabCardLayout: UnoFormField[] = null;
	
	public formBlockFieldLayout: any = AssetFormBlockFieldLayout;

	public permissions = [UserPermissions.ASSET_PORTFOLIO_ASSET_TYPE_CREATE, UserPermissions.ASSET_PORTFOLIO_ASSET_TYPE_EDIT];

	/**
	 * Object being edited in this screen.
	 */
	public type: APAssetType = null;

	/**
	 * Form tabs set for every asset of this asset type.
	 */
	public tabs: APAssetFormTab[] = [];

	/**
	 * Flag to indicate if the page is in create mode.
	 */
	public createMode: boolean = false;

	/**
	 * Object editions' history.
	 */
	public history: any[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.initializeDynamicLayouts();

		this.createMode = false;
		this.type = null;
		this.history = [];

		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		if (data.createMode === true) {
			this.createMode = true;
			this.type = new APAssetType();
			this.type.uuid = generateUUID();

			App.navigator.setTitle('create');
		} else {
			await Promise.all([this.loadData(data.uuid), this.loadTabs(data.uuid)]);

			App.navigator.setTitle(this.type.name || 'edit');
		}
	}

	/**
	 * Initializes the necessary callbacks/properties on the imported layouts
	 */
	public initializeDynamicLayouts(): void {
		this.formTabCardLayout = cloneDeep(AssetFormTabCardLayout);
		
		const usesPrivateBlock = UnoFormUtils.getFormFieldByAttribute(this.formTabCardLayout, 'usesPrivateBlock');
		
		// Get the original info on card to compare with the one being edited since user can go back and forward with usePrivateBlock and we must set back block object accordingly.
		// Original object on DB may already have some private block set, possibly "erased" here on client on usesPrivateBlock changes, in which case we need to get it back again when "user changes his mind" to use a private block again.
		usesPrivateBlock.onChange = async(object: any, row: UnoFormField, value: any): Promise<void> => {
			// Reset card block
			object.blockUuid = null;
			object.block = null;

			let originalCard: APAssetFormTabCard;
			try {
				originalCard = await this.getTabCard(object.uuid);
			} catch {
				originalCard = null;
			}
			
			// If set to use a private block, prepare the form block to be filled with fields. If unset, set old form block selected (if any).
			if (value) {
				// If it was previously using a private block, get it from API. Otherwise just load the selected block from the library (reusable ones).
				if (originalCard && originalCard.usesPrivateBlock && originalCard.blockUuid) {
					// Get original block stored from API
					object.block = await AssetFormBlockService.get(originalCard.blockUuid);
				} else {
					// Create a new block for this card
					this.initializePrivateCardBlock(object);
				}
			} else {
				// If it was previously using a reusable block, get it from API.
				if (originalCard && !originalCard.usesPrivateBlock && originalCard.blockUuid) {
					// Get original block stored from API
					object.blockUuid = originalCard.blockUuid;
					object.block = await AssetFormBlockService.get(originalCard.blockUuid);
				}
			}
		};

		const blockUuid = UnoFormUtils.getFormFieldByAttribute(this.formTabCardLayout, 'blockUuid');
		blockUuid.onChange = async(object: any, row: UnoFormField, value: any): Promise<void> => {
			object.block = await AssetFormBlockService.get(value);
		};
	}

	/**
	 * Get asset type data from the API.
	 * @param uuid - UUID of the object to load.
	 */
	public async loadData(uuid: UUID): Promise<void> {
		try {
			const requestAssetType = await Service.fetch(ServiceList.assetPortfolio.assetType.get, null, null, {uuid: uuid}, Session.session);
			this.type = APAssetType.parse(requestAssetType.response.type);
		} catch {
			App.navigator.pop();
			return;
		}

		this.loadHistory(uuid);
	}

	/**
	 * Load the edit history from database.
	 */
	public async loadHistory(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.assetPortfolio.assetType.history.listUsers, null, null, {uuid: uuid}, Session.session);
		this.history = request.response.history;
	}

	/**
	 * Open a history entry in a modal to display the changes performed on a selected entry.
	 *
	 * @param historyId - History id of the entry from history list to be displayed.
	 */
	public async openHistoryEntry(historyId: number): Promise<void> {
		const request = await Service.fetch(ServiceList.assetPortfolio.assetType.history.get, null, null, {
			uuid: this.type.uuid,
			historyId: historyId
		}, Session.session);
		const selectedEntry: APAssetType = APAssetType.parse(request.response.history);

		const buttons = [{success: false, label: 'close', color: 'primary'}];

		Modal.form(Locale.get('history'), selectedEntry, AssetTypeLayout, buttons, false);
	}

	/**
	 * Loads the tabs of this asset type to be presented on assets of this type.
	 * 
	 * @param uuid - UUID of the asset type to load the tabs from.
	 */
	public async loadTabs(uuid: UUID): Promise<void> {
		this.tabs = FormSortUtils.sortByIndexes(await this.getTypeTabs(uuid));
	}

	/**
	 * Get all the tabs for this asset type.
	 * 
	 * @param uuid - The UUID of the asset to get tabs for.
	 * @returns An array of form tabs.
	 */
	public async getTypeTabs(uuid: UUID): Promise<APAssetFormTab[]> {
		const tabs: APAssetFormTab[] = await AssetTypeService.listTabs(uuid);

		// Sort cards for every tab
		for (let i = 0; i < tabs.length; i++) {
			tabs[i].cards = await AssetTypeService.listTabCards(tabs[i].uuid);
		}

		return tabs;
	}

	/**
	 * Get the card info from API.
	 * 
	 * @param cardUuid - The UUID of the card to get.
	 * @returns A form tab card info.
	 */
	public async getTabCard(cardUuid: UUID): Promise<APAssetFormTabCard> {
		const requestTabCard = await Service.fetch(ServiceList.assetPortfolio.formTabCard.get, null, null, {uuid: cardUuid}, Session.session, false, false);
		return APAssetFormTabCard.parse(requestTabCard.response.card);
	}

	/**
	 * Tells if a card block can be edited.
	 *
	 * Only private blocks can be edited and added fields to.
	 *
	 * @param card - The card to be validated the card belongs to.
	 * @returns true if edition is allowed, false otherwise.
	 */
	public canEditFormBlock(card: APAssetFormTabCard): boolean {
		return !(!card.usesPrivateBlock && card.block && !card.block.privateBlock);
	}

	/**
	 * Update the object in the DB.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		// Check required fields of asset type content
		if (!UnoFormComponent.requiredFilled(this.assetTypeLayout, this.type)) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		// Check tabs forms required fields content
		for (const tab of this.tabs) {
			if (!UnoFormComponent.requiredFilled(this.formTabLayout, tab)) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}

			// Check tab cards forms required fields content
			for (const card of tab.cards) {
				if (!UnoFormComponent.requiredFilled(this.formTabCardLayout, card)) {
					Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
					return;
				}

				// Check block fields forms required fields content
				if (card.block) {
					for (const field of card.block.fields) {
						if (!UnoFormComponent.requiredFilled(this.formBlockFieldLayout, field)) {
							Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
							return;
						}
					}
				}
			}
		}

		try {
			const reqType = await Service.fetch(this.createMode ? ServiceList.assetPortfolio.assetType.create : ServiceList.assetPortfolio.assetType.update, null, null, this.type, Session.session);
			if (!this.createMode && !stayOnPage) {
				this.type = APAssetType.parse(reqType.response.type);
			}

			await Service.fetch(ServiceList.assetPortfolio.formTab.assetType.set, null, null, {tabs: this.tabs, typeUuid: this.type.uuid}, Session.session);
			if (!this.createMode && !stayOnPage) {
				await this.loadTabs(this.type.uuid);
			}
	
			if (this.createMode) {
				Modal.toast(Locale.get('createdSuccessfully'));
			} else {
				Modal.toast(Locale.get('updatedSuccessfully'));
			}
			if (!stayOnPage) {
				App.navigator.pop();
				return;
			}
		} catch {
			Modal.alert(Locale.get('error'), Locale.get(this.createMode ? 'errorCreatingAssetType' : 'errorUpdatingAssetType'));
		}

		// Every time an update occurs, there is an history change.
		if (!this.createMode) {
			this.loadHistory(this.type.uuid);
		}
	}

	/**
	 * Delete the object on database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.assetPortfolio.assetType.delete, null, null, {uuid: this.type.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

	/**
	 * Add a tab to the end of the asset type form.
	 */
	public addTab(): void {
		const tab: APAssetFormTab = new APAssetFormTab();
		tab.uuid = generateUUID();
		tab.name = Locale.get('tab') + ' ' + this.tabs.length;
		tab.typeUuid = this.type.uuid;

		this.tabs.push(tab);
		this.tabs = FormSortUtils.updateIndexes(this.tabs);
	}

	/**
	 * Remove a tab from the asset type form.
	 * 
	 * @param tab - Tab to be removed.
	 */
	public async removeTab(tab: APAssetFormTab): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			const index = this.tabs.indexOf(tab);
			if (index !== -1) {
				this.tabs.splice(index, 1);
				this.tabs = FormSortUtils.updateIndexes(this.tabs);
			}
			// Show the first tab when the displayed tab is removed.
			this.unoTab.tabSections._results[0].active = true;
		}
	}

	/**
	 * Move a tab on the list of tabs.
	 * 
	 * @param event - The drag and drop event.
	 */
	public moveTab(event: CdkDragDrop<APAssetFormTab[]>): any {
		ArrayUtils.move(this.tabs, event.previousIndex, event.currentIndex);
		this.tabs = FormSortUtils.updateIndexes(this.tabs);
	}

	/**
	 * Method used get the index of an item on ngFor loop.
	 * 
	 * @param index - The index of the item being tracked.
	 * @returns - The index of the item being tracked.
	 */
	public trackBy(index: number): any {
		return index;
	}

	/**
	 * Add a card to the end of array of cards of a specific form tab.
	 * 
	 * @param tab - The tab to add the card to.
	 */
	public addTabCard(tab: APAssetFormTab): void {
		const card: APAssetFormTabCard = new APAssetFormTabCard();
		card.uuid = generateUUID();
		card.tabUuid = tab.uuid;

		tab.cards.push(card);
		tab.cards = FormSortUtils.updateIndexes(tab.cards);
	}

	/**
	 * Remove a card from a specific tab.
	 * 
	 * @param tab - The tab where this card exists, to be removed.
	 * @param card - Card to be removed.
	 */
	public removeTabCard(tab: APAssetFormTab, card: APAssetFormTabCard): void {
		const index = tab.cards.indexOf(card);
		if (index !== -1) {
			tab.cards.splice(index, 1);
			tab.cards = FormSortUtils.updateIndexes(tab.cards);
		}
	}

	/**
	 * Move card up in the array of cards of a specific tab.
	 * 
	 * @param tab - The tab where this card exists.
	 * @param card - Card to be moved.
	 */
	public moveTabCardUp(tab: APAssetFormTab, card: APAssetFormTabCard): void {
		const index = tab.cards.indexOf(card);
		if (index < tab.cards.length && index !== -1) {
			ArrayUtils.move(tab.cards, index, index + 1);
			tab.cards = FormSortUtils.updateIndexes(tab.cards);
		}
	}

	/**
	 * Move card down in the array of cards of a specific tab.
	 * 
	 * @param tab - The tab where this card exists. 
	 * @param card - Card to be moved.
	 */
	public moveTabCardDown(tab: APAssetFormTab, card: APAssetFormTabCard): void {
		const index = tab.cards.indexOf(card);
		if (index > 0) {
			ArrayUtils.move(tab.cards, index, index - 1);
			tab.cards = FormSortUtils.updateIndexes(tab.cards);
		}
	}
	
	/**
	 * Initializes a private block for a given card and resets card properties regarding block.
	 * 
	 * @param card - The card to initialize the block for.
	 */
	public initializePrivateCardBlock(card: APAssetFormTabCard): void {
		card.block = new APAssetFormBlock();
		card.block.uuid = generateUUID();
		card.block.privateBlock = true;
		card.block.typeUuid = this.type.uuid;
		card.blockUuid = card.block.uuid;
	}

	/**
	 * Add a field to the end of array of block fields of a specific form card block.
	 * 
	 * @param card - The block to add the field to.
	 */
	public addFormBlockField(card: APAssetFormTabCard): void {
		// Set missing block data when assign fields for the first time
		if (!card.block) {
			this.initializePrivateCardBlock(card);
		}

		const field: APAssetFormBlockField = new APAssetFormBlockField();
		field.uuid = generateUUID();
		field.formBlockUuid = card.block.uuid;

		card.block.fields.push(field);
		card.block.fields = FormSortUtils.updateIndexes(card.block.fields);
	}
	
	/**
	 * Remove a field from a specific card block.
	 * 
	 * @param card - The card where this field exists (inside the card block), to be removed.
	 * @param field - Field to be removed.
	 */
	public removeFormBlockField(card: APAssetFormTabCard, field: APAssetFormBlockField): void {
		const index = card.block.fields.indexOf(field);
		if (index !== -1) {
			card.block.fields.splice(index, 1);
			card.block.fields = FormSortUtils.updateIndexes(card.block.fields);
		}
	}
	
	/**
	 * Move field up in the array of fields of a specific card block.
	 * 
	 * @param card - The card where this block field exists (inside the card block).
	 * @param field - Field to be moved.
	 */
	public moveFormBlockFieldUp(card: APAssetFormTabCard, field: APAssetFormBlockField): void {
		const index = card.block.fields.indexOf(field);
		if (index < card.block.fields.length && index !== -1) {
			ArrayUtils.move(card.block.fields, index, index + 1);
			card.block.fields = FormSortUtils.updateIndexes(card.block.fields);
		}
	}
	
	/**
	 * Move field down in the array of fields of a specific card block.
	 * 
	 * @param card - The card where this field exists (inside the card block).
	 * @param field - Field to be moved.
	 */
	public moveFormBlockFieldDown(card: APAssetFormTabCard, field: APAssetFormBlockField): void {
		const index = card.block.fields.indexOf(field);
		if (index !== -1) {
			ArrayUtils.move(card.block.fields, index, index - 1);
			card.block.fields = FormSortUtils.updateIndexes(card.block.fields);
		}
	}
}
