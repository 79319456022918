import {generateUUID, UUID, UUIDIdentification} from 'src/app/models/uuid';
import {WidgetTypes} from './widget-types';
import {WidgetSize} from './widget-size';

/**
 * Declares the model of a widget.
 */
export class DashboardWidget extends UUIDIdentification {
	/**
	 * Name of the widget.
	 */
	public name: string = '';

	/**
	 * Description of the widget.
	 */
	public description: string = '';

	/**
	 * Type of the widget.
	 */
	public type: WidgetTypes = null;

	/**
	 * Size of the widget.
	 */
	public size: WidgetSize = null;

	/**
	 * Index of the widget inside a dashboard.
	 */
	public index: number = -1;

	/**
	 * If the widget is pinned, it will appear in the dashboard.
	 */
	public pinned: boolean = false;

	/**
	 * Specific data for the widget, will change depending on the type of the widget.
	 */
	public data: any = null;

	/**
	 * UUID of the dashboard.
	 */
	public dashboardUuid: UUID = null;

	/**
	 * Parse widget data from object.
	 *
	 * @param data - Data to be parsed into object.
	 */
	public static parse(data: any): DashboardWidget {
		const widget = new DashboardWidget();

		widget.uuid = data.uuid ?? generateUUID();
		widget.createdAt = new Date(data.createdAt);
		widget.updatedAt = new Date(data.updatedAt);

		widget.name = data.name;
		widget.description = data.description;
		widget.type = data.type;
		widget.size = data.size;
		widget.pinned = data.pinned;
		widget.index = data.index;
		widget.data = data.data;
		widget.dashboardUuid = data.dashboardUuid;

		return widget;
	}
}
