import {AfterContentChecked, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Control, GeoJSONSourceRaw, Map, NavigationControl} from 'mapbox-gl';
import {Geolocation} from '../../../../../models/geolocation';
import {Session} from '../../../../../session';
import {Environment} from '../../../../../../environments/environment';
import {GeolocationUtils} from '../../../../../utils/geolocation-utils';
import {WidgetComponent} from '../widget-component';

@Component({
	selector: 'dashboard-map-widget',
	templateUrl: './dashboard-map-widget.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true
})

export class DashboardMapWidgetComponent implements OnInit, AfterContentChecked, WidgetComponent {
	@ViewChild('mapContainer', {static: true})
	public mapContainer: ElementRef = null;

	/**
	 * Data to display in the map.
	 */
	@Input()
	public data: any = null;

	/**
	 * Config of the widget.
	 */
	@Input()
	public config: any = null;

	/**
	 * GeoJSON data source used to draw points in WebGL mode.
	 */
	public source: GeoJSONSourceRaw = null;

	/**
	 * Mapboxgl instance to display and control the map view.
	 */
	public map: Map = null;

	/**
	 * Used to navigate the map using the mouse or touch controls.
	 */
	public controls: Control = null;

	/**
	 * Current position in the map.
	 */
	public position = new Geolocation(0, 0);

	/**
	 * Indicates if the component is visible or not.
	 *
	 * Used to keep track of the component state and refresh the size of the map
	 */
	public visible: boolean = false;

	public async ngOnInit(): Promise<void> {
		this.map = new Map({
			accessToken: Environment.MAPBOX_TOKEN,
			container: this.mapContainer.nativeElement,
			style: Session.settings.mapStyle,
			zoom: 13,
			center: [this.position.longitude, this.position.latitude],
			attributionControl: false
		});

		this.controls = new NavigationControl();
		this.map.addControl(this.controls);
		await this.getGPSPosition();
		this.map.resize();
	}

	public ngAfterContentChecked(): void {
		if (!this.visible && this.mapContainer.nativeElement.offsetParent !== null) {
			this.visible = true;
			this.map.resize();
		} else if (this.visible && !this.mapContainer.nativeElement.offsetParent) {
			this.visible = false;
		}
		this.map.resize();

	}

	/**
	 * Get position from GPS or browser location API.
	 */
	public async getGPSPosition(): Promise<void> {
		const location = await GeolocationUtils.getLocation();
		this.position.latitude = location.latitude;
		this.position.longitude = location.longitude;
		this.map.flyTo({center: [this.position.longitude, this.position.latitude]});
	}
}
