<div #container class="asset-planning-container">
	<!-- The header -->
	<div class="asset-planning-header" [ngClass]="{'asset-planning-header-mobile':this.app.device.isMobile()}">
		@if (this.multiLine()) {
			<div style="display: flex; flex-direction: column;" class="asset-planning-filters">
				<div style="display: flex; flex-direction: row; width: 100%;">
					<div style="display:flex;align-items: center;flex-direction: row;" [ngStyle]="{'width': '100%', 'flex': this.app.device.isMobile() ? 'none' : '1 1 150px'}">
						<!-- The searchbar -->
						<uno-searchbar style="width: 100%; min-width: 150px;" [ngStyle]="{'max-width': this.app.device.isMobile() ? '100%' :'500px'}" [(value)]="this.search" (valueChange)="this.from = 0; this.loadEvents()"></uno-searchbar>

						<!-- The filter -->
						<uno-icon class="asset-planning-filter-icon" [src]="!this.filterExpanded ? 'assets/components/global-calendar/filter-icon.svg' : 'assets/components/global-calendar/close-icon.svg'" [color]="'var(--primary-70)'" [width]="36" [height]="36" cdkOverlayOrigin #originOverlay="cdkOverlayOrigin" (click)="this.filterExpanded=!this.filterExpanded"></uno-icon>
					</div>

					@if (!app.device.isMobile()) {
						<div class="asset-planning-filters" [ngStyle]="{'max-width': '250px', 'gap': '20px', 'display': 'flex', 'justify-content': 'flex-end'}">
							<!-- The Export Events button -->
							<uno-button type="border" height="28px" width="86px" size="small" [fitContent]="false" (click)="this.exportCalendarXLSX();">{{'export' | translate}}</uno-button>

							<!-- The Create new event button -->
							@if (userPermissions.CALENDAR_EVENT_CREATE | hasPermissions) {
								<uno-button icon="assets/icons/uno/add.svg" color="primary" height="28px" width="134px" size="medium" [fitContent]="false" (click)="this.eventForm();">{{(this.app.device.isMobile() ? 'create' : 'createEvent') | translate}}</uno-button>
							}
						</div>
					}
				</div>
				<div style="display: flex; flex-direction: row; width: 100%; gap: 28px; justify-content: space-between;">
					<div style="display: flex;align-items: center; justify-content: space-between; flex-direction: row; flex: 1 1 150px; width: 100%; min-width: 150px; max-width: 545px;" [ngStyle]=" {'gap': app.device.isDesktop() ? '12px' : '5px'}">
						<!-- The month/day/list selector -->
						<div class="asset-planning-display">
							<button class="asset-planning-display-button" [ngClass]="{'asset-planning-active':this.display == 'month'}" (click)="this.changeDisplayMode(unoCalendarDisplayMode.MONTH)">{{'month' | translate}}</button>
							@if (!this.app.device.isMobile()) {
								<button class="asset-planning-display-button" [ngClass]="{'asset-planning-active':this.display == 'day'}" (click)="this.changeDisplayMode(unoCalendarDisplayMode.DAY)">{{'day' | translate}}</button>
							}
							<button class="asset-planning-display-button" [ngClass]="{'asset-planning-active':this.display == 'list'}" (click)="this.changeDisplayMode(unoCalendarDisplayMode.LIST)">{{'list' | translate}}</button>
						</div>

						@if (this.display !== unoCalendarDisplayMode.LIST) {
							<uno-button width="60px" [fitContent]="false" color="primary" type="border" (click)="this.changeViewDate(this.currentDate)">{{'today' | translate}}</uno-button>
						}

						<!-- The month and year selector on desktop view-->
						@if (this.display !== unoCalendarDisplayMode.LIST && !this.app.device.isMobile()) {
							<div class="asset-planning-selector-container" style="width:250px;">
								<!-- The month selector -->
								<uno-options-list [width]="130" [options]="this.monthList" [value]="this.viewDate.getMonth()" (valueChange)="this.monthChange($event)" [scrollCurrent]="true"></uno-options-list>
								<!-- The year selector -->
								<uno-options-list [width]="90" [options]="this.yearList" [value]="this.viewDate.getFullYear()-(this.currentDate.getFullYear()-100)" (valueChange)="this.yearChange($event)" [scrollCurrent]="true"></uno-options-list>
							</div>
						}

						@if (this.display === unoCalendarDisplayMode.LIST) {
							<uno-date-time (ngModelChange)="this.loadEvents();" (onUpdate)="this.updateRange($event)" [(ngModel)]="this.range" [isRange]="true" [selectedDates]="this.startingDates" [ngModelOptions]="{standalone: true}" placeholder="{{'fromDate' | translate}}" style="display:inline-block;"></uno-date-time>
						}

						@if (app.device.isMobile()) {
							<!-- The Export Events button -->
							<uno-button type="border" width="50px" [fitContent]="false" (click)="this.exportCalendarXLSX();">{{'export' | translate}}</uno-button>

							<!-- The Create new event button -->
							@if (userPermissions.CALENDAR_EVENT_CREATE | hasPermissions) {
								<uno-button color="primary" width="60px" [fitContent]="false" (click)="this.eventForm();">{{(this.app.device.isMobile() ? 'create' : 'createEvent') | translate}}</uno-button>
							}
						}
					</div>
				</div>
				<!-- The filter background -->
				<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.filterExpanded">
					<div class="asset-planning-filter-background" (click)="this.filterExpanded=false"></div>
				</ng-template>

				<!-- The filter dropdown -->
				<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.filterExpanded">
					<div class="asset-planning-filter-container">
						<form [formGroup]="this.filterForm">

							<!-- Container for the "select all" and "Assigned events" options -->
							<div class="filter-sub-container" style="margin-left:20px">
								<!-- The "select all" option -->
								<div>
									<label class="asset-planning-filter-label" for="select">{{'all' | translate}}
										<input id="select" type="checkbox" formControlName="select">
										<span class="asset-planning-checkmark"></span>
									</label>
								</div>
							</div>

							<div class="asset-planning-input-scrollbar-padding">
								<div class="asset-planning-filter-inputs">
									<!-- The asset Event Group -->
									<div class="asset-planning-filter-sub-container" formGroupName="assetEventsGroup">

										<!-- The Asset Events title -->
										<div class="asset-planning-filter-section-title" (click)="this.expand('assetEvents')">
											<span class="asset-planning-filter-section-label">{{'assetEvents' | translate}}</span>
											<uno-icon [src]="'assets/components/global-calendar/up-fullarrow-icon.svg'" [color]="'var(--gray-5)'" [ngClass]="{'asset-planning-rotate': !this.filterForm.get('assetEventsGroup').value.assetEvents}">
											</uno-icon>
										</div>

										<!-- The Asset Events options container -->
										@if (this.filterForm.get('assetEventsGroup').value.assetEvents) {
											<div class="asset-planning-filter-sub-container">
												<!-- The Dynamic Inspection option -->
												<label class="asset-planning-filter-label" for="workflows">{{'workflows' | translate}}
													<input id="workflows" type="checkbox" formControlName="workflows">
													<span class="asset-planning-checkmark"></span>
												</label>
												<!-- The ATEX Inspection option -->
												<label class="asset-planning-filter-label" for="atexInspections">{{'atexInspections' | translate}}
													<input id="atexInspections" type="checkbox" formControlName="atexInspections">
													<span class="asset-planning-checkmark"></span>
												</label>
												<!-- The DL50 Inspection option -->
												<label class="asset-planning-filter-label" for="dl50Inspection">{{'dl50Inspection' | translate}}
													<input id="dl50Inspection" type="checkbox" formControlName="dl50Inspection">
													<span class="asset-planning-checkmark"></span>
												</label>
											</div>
										}
									</div>

									<!-- The repair Event Group -->
									<div class="asset-planning-filter-sub-container" formGroupName="repairEventsGroup">

										<!-- The Repair Event title -->
										<div class="asset-planning-filter-section-title" (click)="this.expand('repairEvents')">
											<span class="asset-planning-filter-section-label">{{'repairEvents' | translate}}</span>
											<uno-icon [src]="'assets/components/global-calendar/up-fullarrow-icon.svg'" [color]="'var(--gray-5)'" [ngClass]="{'asset-planning-rotate': !this.filterForm.get('repairEventsGroup').value.repairEvents}"></uno-icon>
										</div>

										<!-- The Repair Events options container -->
										@if (this.filterForm.get('repairEventsGroup').value.repairEvents) {
											<div class="asset-planning-filter-sub-container">
												<!-- The Repair Inspection option -->
												<label class="asset-planning-filter-label" for="repairInspection">{{'repairInspection' | translate}}
													<input id="repairInspection" type="checkbox" formControlName="repairInspection">
													<span class="asset-planning-checkmark"></span>
												</label>
												<!-- The Definitive Repair option -->
												<label class="asset-planning-filter-label" for="definitiveRepair">{{'definitiveRepair' | translate}}
													<input id="definitiveRepair" type="checkbox" formControlName="definitiveRepair">
													<span class="asset-planning-checkmark"></span>
												</label>
											</div>
										}
									</div>

									<!-- The Scheduled Event Group -->

									<div class="asset-planning-filter-sub-container" formGroupName="scheduledEventsGroup">

										<!-- The Scheduled events title -->
										<div class="asset-planning-filter-section-title" (click)="this.expand('scheduledEvents')">
											<span class="asset-planning-filter-section-label">{{'scheduledEvents' | translate}}</span>
											<uno-icon [src]="'assets/components/global-calendar/up-fullarrow-icon.svg'" [color]="'var(--gray-5)'" [ngClass]="{'asset-planning-rotate': !this.filterForm.get('scheduledEventsGroup').value.scheduledEvents}"></uno-icon>
										</div>

										<!-- The Scheduled Events options container -->
										@if (this.filterForm.get('scheduledEventsGroup').value.scheduledEvents) {
											<div class="asset-planning-filter-sub-container">
												<!-- The Planned Maintenance Stoppage option -->
												<label class="asset-planning-filter-label" for="stopMaintenance">{{'stopMaintenance' | translate}}
													<input id="stopMaintenance" type="checkbox" formControlName="stopMaintenance">
													<span class="asset-planning-checkmark"></span>
												</label>
												<!-- The Audit option -->
												<label class="asset-planning-filter-label" for="audit">{{'audit' | translate}}
													<input id="audit" type="checkbox" formControlName="audit">
													<span class="asset-planning-checkmark"></span>
												</label>
											</div>
										}
									</div>
								</div>

								<!-- Padding for the filter scrollbar -->
								<div class="asset-planning-scrollbar-padding"></div>
							</div>
						</form>
					</div>
				</ng-template>
			</div>
		}
		@else {
			<div class="asset-planning-filters">
				<div style="display:flex;align-items: center;flex-direction: row;" [ngStyle]="{'width': '100%', 'max-width': this.app.device.isMobile() ? '100%' :'430px', 'flex': this.app.device.isMobile() ? 'none' : '1 1 150px'}">
					<!-- The searchbar -->
					<uno-searchbar style="width: 100%;min-width: 150px;" [ngStyle]="{'max-width': this.app.device.isMobile() ? '100%' :'400px'}" [(value)]="this.search" (valueChange)="this.from = 0; this.loadEvents()"></uno-searchbar>

					<!-- The filter -->
					<uno-icon class="asset-planning-filter-icon" [src]="!this.filterExpanded ? 'assets/components/global-calendar/filter-icon.svg' : 'assets/components/global-calendar/close-icon.svg'" [color]="'var(--primary-70)'" [width]="36" [height]="36" cdkOverlayOrigin #originOverlay="cdkOverlayOrigin" (click)="this.filterExpanded=!this.filterExpanded"></uno-icon>
				</div>

				<div style="display: flex;align-items: center; flex-direction: row; gap: 20px; width: 275px">
					<!-- The month/day/list selector -->
					<div class="asset-planning-display">
						<button class="asset-planning-display-button" [ngClass]="{'asset-planning-active':this.display == 'month'}" (click)="this.changeDisplayMode(unoCalendarDisplayMode.MONTH)">{{'month' | translate}}</button>
						@if (!this.app.device.isMobile()) {
							<button class="asset-planning-display-button" [ngClass]="{'asset-planning-active':this.display == 'day'}" (click)="this.changeDisplayMode(unoCalendarDisplayMode.DAY)">{{'day' | translate}}</button>
						}
						<button class="asset-planning-display-button" [ngClass]="{'asset-planning-active':this.display == 'list'}" (click)="this.changeDisplayMode(unoCalendarDisplayMode.LIST)">{{'list' | translate}}</button>
					</div>

					@if (this.display !== unoCalendarDisplayMode.LIST) {
						<uno-button width="80px" [fitContent]="false" color="primary" type="border" (click)="this.changeViewDate(this.currentDate)">{{'today' | translate}}</uno-button>
					}
				</div>
				<div>
					<!-- The month and year selector on desktop view-->
					@if (this.display !== unoCalendarDisplayMode.LIST && !this.app.device.isMobile()) {
						<div class="asset-planning-selector-container">
							<!-- The month selector -->
							<uno-options-list [width]="130" [options]="this.monthList" [value]="this.viewDate.getMonth()" (valueChange)="this.monthChange($event)" [scrollCurrent]="true"></uno-options-list>
							<!-- The year selector -->
							<uno-options-list [width]="90" [options]="this.yearList" [value]="this.viewDate.getFullYear()-(this.currentDate.getFullYear()-100)" (valueChange)="this.yearChange($event)" [scrollCurrent]="true"></uno-options-list>
						</div>
					}

					@if (this.display === unoCalendarDisplayMode.LIST) {
						<uno-date-time (ngModelChange)="this.loadEvents();" [(ngModel)]="this.range" [isRange]="true" [selectedDates]="this.startingDates" [ngModelOptions]="{standalone: true}" placeholder="{{'fromDate' | translate}}" style="display:inline-block;"></uno-date-time>
					}
				</div>
				<!-- The filter background -->
				<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.filterExpanded">
					<div class="asset-planning-filter-background" (click)="this.filterExpanded=false"></div>
				</ng-template>

				<!-- The filter dropdown -->
				<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.filterExpanded">
					<div class="asset-planning-filter-container">
						<form [formGroup]="this.filterForm">

							<!-- Container for the "select all" and "Assigned events" options -->
							<div class="filter-sub-container" style="margin-left:20px">
								<!-- The "select all" option -->
								<div>
									<label class="asset-planning-filter-label" for="select">{{'all' | translate}}
										<input id="select" type="checkbox" formControlName="select">
										<span class="asset-planning-checkmark"></span>
									</label>
								</div>
							</div>

							<div class="asset-planning-input-scrollbar-padding">
								<div class="asset-planning-filter-inputs">
									<!-- The asset Event Group -->
									<div class="asset-planning-filter-sub-container" formGroupName="assetEventsGroup">

										<!-- The Asset Events title -->
										<div class="asset-planning-filter-section-title" (click)="this.expand('assetEvents')">
											<span class="asset-planning-filter-section-label">{{'assetEvents' | translate}}</span>
											<uno-icon [src]="'assets/components/global-calendar/up-fullarrow-icon.svg'" [color]="'var(--gray-5)'" [ngClass]="{'asset-planning-rotate': !this.filterForm.get('assetEventsGroup').value.assetEvents}">
											</uno-icon>
										</div>

										<!-- The Asset Events options container -->
										@if (this.filterForm.get('assetEventsGroup').value.assetEvents) {
											<div class="asset-planning-filter-sub-container">
												<!-- The Dynamic Inspection option -->
												<label class="asset-planning-filter-label" for="workflows">{{'workflows' | translate}}
													<input id="workflows" type="checkbox" formControlName="workflows">
													<span class="asset-planning-checkmark"></span>
												</label>
												<!-- The ATEX Inspection option -->
												<label class="asset-planning-filter-label" for="atexInspections">{{'atexInspections' | translate}}
													<input id="atexInspections" type="checkbox" formControlName="atexInspections">
													<span class="asset-planning-checkmark"></span>
												</label>
												<!-- The DL50 Inspection option -->
												<label class="asset-planning-filter-label" for="dl50Inspection">{{'dl50Inspection' | translate}}
													<input id="dl50Inspection" type="checkbox" formControlName="dl50Inspection">
													<span class="asset-planning-checkmark"></span>
												</label>
											</div>
										}
									</div>

									<!-- The repair Event Group -->
									<div class="asset-planning-filter-sub-container" formGroupName="repairEventsGroup">

										<!-- The Repair Event title -->
										<div class="asset-planning-filter-section-title" (click)="this.expand('repairEvents')">
											<span class="asset-planning-filter-section-label">{{'repairEvents' | translate}}</span>
											<uno-icon [src]="'assets/components/global-calendar/up-fullarrow-icon.svg'" [color]="'var(--gray-5)'" [ngClass]="{'asset-planning-rotate': !this.filterForm.get('repairEventsGroup').value.repairEvents}"></uno-icon>
										</div>

										<!-- The Repair Events options container -->
										@if (this.filterForm.get('repairEventsGroup').value.repairEvents) {
											<div class="asset-planning-filter-sub-container">
												<!-- The Repair Inspection option -->
												<label class="asset-planning-filter-label" for="repairInspection">{{'repairInspection' | translate}}
													<input id="repairInspection" type="checkbox" formControlName="repairInspection">
													<span class="asset-planning-checkmark"></span>
												</label>
												<!-- The Definitive Repair option -->
												<label class="asset-planning-filter-label" for="definitiveRepair">{{'definitiveRepair' | translate}}
													<input id="definitiveRepair" type="checkbox" formControlName="definitiveRepair">
													<span class="asset-planning-checkmark"></span>
												</label>
											</div>
										}
									</div>

									<!-- The Scheduled Event Group -->

									<div class="asset-planning-filter-sub-container" formGroupName="scheduledEventsGroup">

										<!-- The Scheduled events title -->
										<div class="asset-planning-filter-section-title" (click)="this.expand('scheduledEvents')">
											<span class="asset-planning-filter-section-label">{{'scheduledEvents' | translate}}</span>
											<uno-icon [src]="'assets/components/global-calendar/up-fullarrow-icon.svg'" [color]="'var(--gray-5)'" [ngClass]="{'asset-planning-rotate': !this.filterForm.get('scheduledEventsGroup').value.scheduledEvents}"></uno-icon>
										</div>

										<!-- The Scheduled Events options container -->
										@if (this.filterForm.get('scheduledEventsGroup').value.scheduledEvents) {
											<div class="asset-planning-filter-sub-container">
												<!-- The Planned Maintenance Stoppage option -->
												<label class="asset-planning-filter-label" for="stopMaintenance">{{'stopMaintenance' | translate}}
													<input id="stopMaintenance" type="checkbox" formControlName="stopMaintenance">
													<span class="asset-planning-checkmark"></span>
												</label>
												<!-- The Audit option -->
												<label class="asset-planning-filter-label" for="audit">{{'audit' | translate}}
													<input id="audit" type="checkbox" formControlName="audit">
													<span class="asset-planning-checkmark"></span>
												</label>
											</div>
										}
									</div>
								</div>

								<!-- Padding for the filter scrollbar -->
								<div class="asset-planning-scrollbar-padding"></div>
							</div>
						</form>
					</div>
				</ng-template>
			</div>

			<div class="asset-planning-filters" [ngStyle]="{'max-width': '250px', 'gap': '5px', 'display': 'flex', 'justify-content': 'flex-end'}">
				<!-- The Export Events button -->
				<uno-button type="border" width="86px" [fitContent]="false" (click)="this.exportCalendarXLSX();">{{'export' | translate}}</uno-button>

				<!-- The Create new event button -->
				@if (userPermissions.CALENDAR_EVENT_CREATE | hasPermissions) {
					<uno-button icon="assets/icons/uno/add.svg" color="primary" width="134px" [fitContent]="false" (click)="this.eventForm();">{{(this.app.device.isMobile() ? 'create' : 'createEvent') | translate}}</uno-button>
				}
			</div>
		}
	</div>

	<!-- The calendar -->
	<div class="asset-planning-calendar-container">
		<uno-calendar [filterList]="this.filterList" [events]="this.events" [(viewDate)]="this.viewDate" [displayMode]="this.display" [hasMore]="this.hasMore" (createEvent)="this.eventForm($event);" (viewChanged)="this.changeViewDate($event);" (eventClicked)="this.navigateToAction($event)" (sortChanged)="this.changeSortDirection($event)" (actionClicked)="this.eventClickAction($event)" (loadMore)="this.loadMore($event)" #unoCalendar></uno-calendar>
	</div>

	<!-- The legend for the calendar -->
	<div class="asset-planning-legend">
		<!-- Legend containing the event subtypes -->
		@for (color of this.calendarEventSubtypeColors | keyvalue; track color) {
			<div class="asset-planning-legend-member">
				<span class="asset-planning-legend-icon" [ngStyle]="{'background-color':this.calendarEventSubtypeColors.get(color.key)}"></span>
				<span class="asset-planning-legend-label">{{this.calendarEventSubtypesLabel.get(color.key) | translate}}</span>
			</div>
		}
	</div>
</div>
