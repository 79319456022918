import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {CSSUtils} from 'src/app/utils/css-utils';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {WidgetViewTypes} from 'src/app/models/dashboards/widget-view-types';
import {RepairService} from 'src/app/modules/repairs/repair-work/services/repair.service';
import {RepairStatus, RepairStatusLabel} from 'src/app/models/repairs/repairs/repair-status';
import {cloneDeep} from 'lodash-es';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {ChartData} from '../../../components/widget/widget.component';
import {Widget} from '../../widget-interface';
import {DashboardDoughnutChartWidgetComponent} from '../../../components/widgets/doughnut-chart-widget/dashboard-doughnut-chart-widget.component';
import {WidgetBaseLayout} from '../../../screens/widget-layout';

export class WidgetRepairProgress implements Widget {
	public type = WidgetTypes.REPAIR_BY_STATUS;

	public component = DashboardDoughnutChartWidgetComponent;
	
	public config = function(): void {
		this.chartType = WidgetViewTypes.DOUGHNUT_CHART;
		this.repairStatuses = [];
	};

	public permissions = [UserPermissions.REPAIR];

	public async getData(widgetData: DashboardWidget): Promise<ChartData> {
		// Count number of repairs of each status
		const request = await RepairService.countStatusAll(true, false);

		let chartLabels: string[] = [];

		let datasetData: number[] = [];

		if (!widgetData.data.repairStatuses || widgetData.data.repairStatuses.length === 0 || widgetData.data.repairStatuses.length === 7) {
			chartLabels = [
				Locale.get(RepairStatusLabel.get(RepairStatus.PROPOSAL)),
				Locale.get(RepairStatusLabel.get(RepairStatus.WAITING_PROPOSAL_APPROVAL)),
				Locale.get(RepairStatusLabel.get(RepairStatus.ON_GOING)),
				Locale.get(RepairStatusLabel.get(RepairStatus.QUALITY_ASSURANCE)),
				Locale.get(RepairStatusLabel.get(RepairStatus.IMPLEMENTED)),
				Locale.get(RepairStatusLabel.get(RepairStatus.BLOCKED)),
				Locale.get(RepairStatusLabel.get(RepairStatus.ARCHIVED))
			];

			datasetData = [
				request.waitingProposal,
				request.waitingProposalApproval,
				request.onGoing,
				request.waitingDone,
				request.implemented,
				request.blocked,
				request.archived
			];
		} else {
			for (const status of widgetData.data.repairStatuses) {
				switch (status) {
					case RepairStatus.PROPOSAL:
						chartLabels.push(Locale.get(RepairStatusLabel.get(RepairStatus.PROPOSAL)));
						datasetData.push(request.waitingProposal);
						break;

					case RepairStatus.WAITING_PROPOSAL_APPROVAL:
						chartLabels.push(Locale.get(RepairStatusLabel.get(RepairStatus.WAITING_PROPOSAL_APPROVAL)));
						datasetData.push(request.waitingProposalApproval);
						break;

					case RepairStatus.ON_GOING:
						chartLabels.push(Locale.get(RepairStatusLabel.get(RepairStatus.ON_GOING)));
						datasetData.push(request.onGoing);
						break;

					case RepairStatus.QUALITY_ASSURANCE:
						chartLabels.push(Locale.get(RepairStatusLabel.get(RepairStatus.QUALITY_ASSURANCE)));
						datasetData.push(request.waitingDone);
						break;

					case RepairStatus.IMPLEMENTED:
						chartLabels.push(Locale.get(RepairStatusLabel.get(RepairStatus.IMPLEMENTED)));
						datasetData.push(request.implemented);
						break;

					case RepairStatus.BLOCKED:
						chartLabels.push(Locale.get(RepairStatusLabel.get(RepairStatus.BLOCKED)));
						datasetData.push(request.blocked);
						break;

					case RepairStatus.ARCHIVED:
						chartLabels.push(Locale.get(RepairStatusLabel.get(RepairStatus.ARCHIVED)));
						datasetData.push(request.archived);
						break;

					default:
						throw new Error('Invalid Repair Status');
				}
			}
		}

		const chartData = {
			labels: chartLabels,
			datasets: [
				{
					label: '',
					data: datasetData,
					backgroundColor: [
						CSSUtils.getVariable('--primary-70'),
						CSSUtils.getVariable('--success-normal'),
						CSSUtils.getVariable('--warning-normal'),
						CSSUtils.getVariable('--error-normal'),
						CSSUtils.getVariable('--special-violet-1'),
						CSSUtils.getVariable('--special-blue-2'),
						CSSUtils.getVariable('--special-yellow-2')
					],
					borderColor: [
						CSSUtils.getVariable('--primary-70'),
						CSSUtils.getVariable('--success-normal'),
						CSSUtils.getVariable('--warning-normal'),
						CSSUtils.getVariable('--error-normal'),
						CSSUtils.getVariable('--special-violet-1'),
						CSSUtils.getVariable('--special-blue-2'),
						CSSUtils.getVariable('--special-yellow-2')
					],
					cutout: '70%'
				}
			]
		};

		return chartData;
	};

	public async openEditModal(widget: DashboardWidget): Promise<DashboardWidget> {
		const editLayout = cloneDeep(WidgetBaseLayout);
		editLayout.push({
			required: false,
			label: 'status',
			attribute: 'data.repairStatuses',
			multiple: true,
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
			options: [
				{label: Locale.get('waitingProposal'), value: RepairStatus.PROPOSAL},
				{label: Locale.get('waitingProposalApproval'), value: RepairStatus.WAITING_PROPOSAL_APPROVAL},
				{label: Locale.get('onGoing'), value: RepairStatus.ON_GOING},
				{label: Locale.get('qualityAssurance'), value: RepairStatus.QUALITY_ASSURANCE},
				{label: Locale.get('implemented'), value: RepairStatus.IMPLEMENTED},
				{label: Locale.get('blocked'), value: RepairStatus.BLOCKED},
				{label: Locale.get('archived'), value: RepairStatus.ARCHIVED}
			]
		});

		const w = cloneDeep(widget);

		widget = await Modal.form(Locale.get('edit'), w, editLayout);

		return widget;
	}
}
