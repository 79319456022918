import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {DemoComponentsPage} from 'src/app/modules/demo/demo/components/demo-components.page';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {cloneDeep} from 'lodash-es';
import {ChartData} from 'chart.js';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {WidgetViewTypes} from 'src/app/models/dashboards/widget-view-types';
import {AsyncUtils} from 'src/app/utils/async-utils';
import {WidgetBaseLayout} from '../../../screens/widget-layout';
import {UnoGenericWidgetComponent} from '../../../components/widgets/generic-widget/generic-widget.component';
import {Widget} from '../../widget-interface';

export class WidgetDemo implements Widget {
	public type = WidgetTypes.DEMO_WIDGET;

	public component = UnoGenericWidgetComponent;

	public config = function(): void {
		this.chartType = WidgetViewTypes.BAR_CHART;
	};

	public permissions = [];

	public async getData(widget: DashboardWidget): Promise<ChartData> {
		await AsyncUtils.await(10);
		
		return DemoComponentsPage.loadDemoData(widget);
	};

	/**
	 * Opens the edit modal for the DEMO widget and returns the updated widget.
	 * 
	 * @param widget - The widget to update.
	 * @returns Updated widget after the modal is closed
	 */
	public async openEditModal(widget: DashboardWidget): Promise<DashboardWidget> {
		const w = cloneDeep(widget);
		
		widget = await Modal.form(Locale.get('edit'), w, WidgetBaseLayout);

		return widget;
	}
}
