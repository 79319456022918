@if (this.displayMode === this.optionsDisplayMode.MODAL) {
	<div class="uno-options-input" (click)="this.toggleModal()" cdkOverlayOrigin #originOverlay="cdkOverlayOrigin" [ngClass]="{'selected': this.expanded}">
		<span class="uno-options-label"> {{this.getLabel(this.value) | translate}}</span>
		<uno-icon class="uno-options-icon" [ngClass]="{'rotate': !this.expanded, 'disabled': this.disabled}" color="{{!this.expanded ? 'var(--gray-5)' : 'var(--primary-70)' }}" [width]="32" [height]="32" src="./assets/components/options/up-specialarrow-icon.svg"></uno-icon>
	</div>
	<ion-item class="ion-no-margin ion-no-padding uno-options-ion-selector" lines="none" style="--padding-end: 0px; --inner-padding-end: 0px;">
		<ion-select #ionSelect fill="outline" (click)="formUtils.fetchOptions([this.row], this.object);" (ngModelChange)="this.updateValue($event);" (ionDismiss)="this.expanded = false" [disabled]="this.disabled" [ngModel]="this.value" [ngModelOptions]="{standalone: true}" interface="{{app.device.isMobile() || this.displayMode === this.optionsDisplayMode.MODAL ? 'alert' : 'popover' }}">
			@for (option of this.options; track option) {
				<ion-select-option [value]="option.value">
					{{this.translate ? (option.label | translate) : option.label}}
				</ion-select-option>
			}
		</ion-select>
	</ion-item>
} @else {
	<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.expanded">
		<div class="uno-options-unscrollable" (click)="this.closing = true; this.toggle()"></div>
	</ng-template>

	<div class="uno-options-container" [ngClass]="{'disabled': this.disabled}">
		<div #optionsInput class="uno-options-input" (click)="this.toggle()" cdkOverlayOrigin #originOverlay="cdkOverlayOrigin" [ngClass]="{'selected': this.expanded}">
			<span class="uno-options-label"> {{this.getLabel(this.value) | translate}}</span>
			<uno-icon class="uno-options-icon" [ngClass]="{'rotate': !this.expanded, 'disabled': this.disabled}" color="{{!this.expanded ? 'var(--gray-5)' : 'var(--primary-70)' }}" [width]="32" [height]="32" src="./assets/components/options/up-specialarrow-icon.svg"></uno-icon>
		</div>
		<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.expanded">
			<div class="uno-options-dropdown" [style.height.px]="height" [ngClass]="{'close': this.closing}">
				<!-- Options -->
				<div class="uno-options-dropdown-option-container">
					@for (option of this.options; track option) {
						<span class="uno-options-label uno-options-dropdown-option" [style.width.px]="width-20" [attr.aria-label]="this.getLabel(this.value)" (click)="this.closing = true; this.updateValue(option.value)">{{option.label | translate}} </span>
					}
				</div>
				<div class="uno-options-scrollbar-padding"></div>
			</div>
		</ng-template>
	</div>
}
