<div style="height: 110px; margin: 10px;">
	<div style="display:flex; align-items: center; gap: 5px;">
		<!-- Create button -->
		@if (session.user.isAdmin) {
			<uno-button icon="assets/icons/uno/add.svg" (click)="app.navigator.navigate('/menu/role/edit', {createMode: true})">
				@if (app.device.isDesktop()) {
					{{'create' | translate}}
				}
			</uno-button>
		}

		<!-- Search bar -->
		<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="this.onSearchChange($event)"></uno-searchbar>
	</div>

	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

@if (app.device.isDesktop()) {
	<!-- Desktop table -->
	<div style="height: calc(100% - 130px); overflow: hidden;  padding: 0px 10px;">
		<uno-table [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="selfStatic.tablePageSize" [selectable]="false" (rowClick)="app.navigator.navigate('/menu/role/edit', {uuid: $event.element.uuid});" (sortChange)="this.sortChanged($event.sortBy)">
		</uno-table>
	</div>
}
@else {
	<!-- Mobile list -->
	<ng-template #itemTemplate let-role="item">
		<uno-list-item (click)="app.navigator.navigate('/menu/role/edit', {uuid: role.uuid});" style="height: 48px;">
			<uno-list-item-icon>
				<div slot="start" style="line-height: 40px; color: var(--gray-13); font-size: 17px;
					  border-radius: 50%; text-align: center; background-color: var(--primary-70); width: 40px; height:40px;">{{role.permissions.length}}</div>
			</uno-list-item-icon>

			<uno-list-item-label>
				<h2>{{role.name}}</h2>
				<h3>{{role.description}}</h3>
			</uno-list-item-label>
		</uno-list-item>
	</ng-template>

	<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
		<uno-list [handler]="this.handler" [itemTemplate]="itemTemplate"></uno-list>
	</uno-content>
}
