import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';

/**
 * The base dashboard layout.
 */
export const DashboardBaseLayout: UnoFormField[] = [
	{
		required: true,
		label: 'name',
		attribute: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		attribute: 'pinned',
		label: 'pinDashboard',
		type: UnoFormFieldTypes.CHECKBOX
	},
	{
		required: true,
		attribute: 'index',
		label: 'index',
		type: UnoFormFieldTypes.NUMBER
	}
];
